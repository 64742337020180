import { getNetworkInfo } from "../../services/marketServices";
import { coinUsersBalance } from "../../services/userServices";

export const TRANSFER_TYPE_BUTTONS = [
  { id: "internal", name: "انتقال داخلی" },
  { id: "external", name: "کیف پول" },
];

export const EXCEPTEC_ERRORS_WIH = {
  network: "شبکه انتقال را انتخاب کنید",
  amount: "مقدار برداشت را وارد کنید",
  wallet: "آدرس کیف پول مقصد را وارد کنید",
  minAmount: "حداقل مقدار برداشت رعایت نشده است",
  gaCode: "کد دوعاملی شما فعال نمیباشد",
  document: "به دلیل واریز ریالی باید مدارک خود را تکمیل کنید",
  yourBalance: "موجودی شما کافی نمیباشد",
  email: "ایمیل را وارد کنید",
  checkFee:"کارمزد انتقال شبکه را بررسی کنید"
};

export const initialStates = {
  networkModal: false,
  view: "external",
  isLoading: true,
  refreshCount: 0,
  isError: false,
  gaCode: false,
  asset: {},
  chains: [],
  tagNames: {},
  hasTag: [],
  selectedChain: {},
};

export const WithdrawReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE_VIEW":
      return { ...state, view: action.payload };
    case "INIT_FAILED":
      return { ...state, isError: true, isLoading: false };
    case "INIT_SUCCESS":

      return {
        ...state,
        isError: false,
        isLoading: false,
        networkModal: true,
        asset: action.payload.wallet.asset,
        gaCode: action.payload.wallet.gaCode,
        chains: action.payload.chains["data"],
        tagNames: action.payload.chains["withTag"]["TagName"],
        hasTag: action.payload.chains["withTag"]["WithdrawWithTagCoins"],
      };
    case "UPDATE":
      return { ...state, asset: action.payload.asset };
    case "REFRESH":
      return {
        ...state,
        isLoading: true,
        isError: false,
        refreshCount: ++state.refreshCount,
      };
    case "CHANGE_MODAL_NETWORK":
      return { ...state, networkModal: action.payload };
    case "LOADING":
      return { ...state, isLoading: action.payload };
    case "SELECT_CHAIN":
      return { ...state, selectedChain: action.payload, networkModal: false };
    default:
      return state;
  }
};

export async function getWithdrawalSymbolDetails(symbol, signal) {
  const res = {
    error: false,
    data: {},
  };
  try {
    const { data, status } = await coinUsersBalance(symbol, signal);
    if (status === 200) {
      res.data = data;
    } else {
      res.error = true;
    }
  } catch {
    res.error = true;
  } finally {
    return res;
  }
}

export async function symbolChainDetails(symbol, signal) {
  const res = {
    error: false,
    data: {},
  };
  try {
    const { data, status } = await getNetworkInfo(symbol, signal);
    if (status === 200) {
      res.data = data;
    } else {
      res.error = true;
    }
  } catch {
    res.error = true;
  } finally {
    return res;
  }
}
