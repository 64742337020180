import { Stack } from "@mui/system";
import { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
//# STYLES ##
import styles from "./RandomCoin.module.scss";
import { getRandomNum } from "../../../utils/priceFunc";
import { Typography } from "@mui/material";
import appIcons from "../../../utils/appIcons";
import { Link } from "react-router-dom";
import serviceConfig from "../../../services/config.json";

function RandomCoin() {
  const MarketFromAPI = useSelector((state) => state.market.data);
  const stateReducer = useSelector((state) => state.stateReducer);
  const [randomCoinSelected, setRandomCoinSelected] = useState({});

  const handleShowPrice = (symbol) => {
    if (!_.isEmpty(stateReducer)) {
      if (stateReducer[symbol + "USDT"]) {
        return stateReducer[symbol + "USDT"].last;
      } else {
        return "...";
      }
    } else {
      return "...";
    }
  };

  useEffect(() => {
    if (!_.isEmpty(MarketFromAPI) && _.isEmpty(randomCoinSelected)) {
      const RandomNumber = getRandomNum(0, 30);
      setRandomCoinSelected(MarketFromAPI[RandomNumber]);
    }
  }, [MarketFromAPI]);

  return (
    <Link
      to={`/market/trade?market=${randomCoinSelected["symbol"]}USDT`}
      className={styles._a}
    >
      <Stack className={styles.container}>
        <Stack className={styles.image}>
          {randomCoinSelected["symbol"] && (
            <img
              alt={randomCoinSelected["symbol"]}
              src={`${serviceConfig.image_url}/${randomCoinSelected["symbol"]}.png`}
              width={60}
              height={60}
            />
          )}
        </Stack>
        <Stack className={styles.name_percent}>
          <Typography component="strong">
            {randomCoinSelected["symbol"]}
          </Typography>
          <Typography
            component="span"
            data-positive={randomCoinSelected["percent_change_24h"] >= 0}
          >
            {randomCoinSelected["percent_change_24h"] >= 0 && "+"}
            {randomCoinSelected["percent_change_24h"]}%
          </Typography>
        </Stack>
        <Stack className={styles.price}>
          {handleShowPrice(randomCoinSelected["symbol"])}
          <span>USDT</span>
        </Stack>
        <Stack
          className={styles.message}
          data-positive={randomCoinSelected["percent_change_24h"] >= 0}
        >
          <Typography component="p">تغییر ۲۴ ساعت</Typography>
          <Typography
            component="span"
            data-positive={randomCoinSelected["percent_change_24h"] >= 0}
          >
            {appIcons("ArrowTwoToneIcon")}
          </Typography>
        </Stack>
      </Stack>
    </Link>
  );
}
export default memo(RandomCoin);
