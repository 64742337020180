export const stateReducer = (state = {}, action) => {
  switch (action.type) {
    case "UPDATE_STATE_SUBSCRIBE":
      return action.payload;
    default:
      return state;
  }
};
export const dealsReducer = (state = {}, action) => {
  switch (action.type) {
    case "UPDATE_DEALS_SUBSCRIBE":
      const { payload } = action;
      const symbol = Object.keys(payload)[0];
      let data = payload[symbol];
      if (data.length > 100) {
        data.length = 100;
        return { [symbol]: data };
      }
      if (state[symbol]) {
        const newData = state[symbol];
        newData.unshift(...payload[symbol]);
        newData.length = 100;
        state[symbol] = newData;
        return state;
      }
      return {};
    default:
      return state;
  }
};
export const depthReducer = (state = {}, action) => {
  switch (action.type) {
    case "UPDATE_DEPTH_SUBSCRIBE":
      return action.payload;
    default:
      return state;
  }
};
