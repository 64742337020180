import { Alert, Box, Snackbar, Stack } from "@mui/material";
import React, { useMemo } from "react";
import InputViaButton from "../Common/InputViaButton";
import InputViaTakePic from "../Common/InputViaTakePic";
import { QrReader } from "react-qr-reader";
import { ErrorOutlineOutlined } from "@mui/icons-material";
import styles from "./WithdrawalCoinPage.module.scss";
import BottomModal from "../../components/Modals/BottomModal";
import { useState } from "react";
import appIcons from "../../utils/appIcons";
import PaperButton from "../../components/Buttons/PaperButton";
import MessageModal from "../Common/MessageModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { isEmpty } from "lodash";
import { EXCEPTEC_ERRORS_WIH } from "./states";
import Confirm2FaCode from "./Confirm2FaCode";
import { useSelector } from "react-redux";

function ExternalTransferView({ state, dispatch }) {
  const history = useHistory();
  const userPayments = useSelector((state) => state.userPaymentValidity);

  const [viewStates, setViewStates] = useState({
    amount: "",
    wallet: "",
    tag: "",
    cameraIsOpen: false,
    twoFacCode: "",
  });

  
  const [errorInfo, setErrorInfo] = useState({ show: false, text: "" });
  //# A >Excepted Error Via Modal
  //# A >1.Page Load Error, 2.Can't Withdrawal Error
  const [withdrawalErrors, setWithdrawalErrors] = useState([]);
  const [openTwoFacCode, setOpenTwoFacCode] = useState(false);

  const onRequestAllBalance = () => {
    let feePrice = 0;
    const userBalance = parseFloat(
      state["asset"]["balance"] - state["asset"]["locked"]
    );
    if (userBalance <= 0)
      return setViewStates((prev) => ({ ...prev, amount: 0 }));
    if (state["selectedChain"]?.chain) {
      feePrice = state["selectedChain"]["withdraw_tx_fee"];
    }
    setViewStates((prev) => ({
      ...prev,
      amount: userBalance - feePrice,
    }));
  };
  const onRequestPasteTag = async () => {
    if (navigator) {
      await navigator.clipboard
        .readText()
        .then((res) => {
          setViewStates((prev) => ({ ...prev, tag: res }));
        })
        .catch((error) => console.log(error));
    }
  };
  const onUpdateViewStates = (e, target) => {
    setViewStates((prev) => ({ ...prev, [target]: e.target.value }));
  };
  const handleCloseSnackError = () => {
    setErrorInfo((prev) => ({ ...prev, show: false }));
  };
  const onRequestPaste = async () => {
    if (navigator) {
      await navigator.clipboard
        .readText()
        .then((res) => {
          setViewStates((prev) => ({ ...prev, wallet: res }));
        })
        .catch((error) => console.log(error));
    }
  };
  const onRequestCloseCamera = () => {
    setViewStates((prev) => ({ ...prev, cameraIsOpen: false }));
  };
  const handleQrCodeReaded = (res) => {
    setViewStates((prev) => ({
      ...prev,
      cameraIsOpen: false,
      wallet: res?.text,
    }));
  };
  const onRequestTakePic = async () => {
    const deviceHasCamera =
      "mediaDevices" in navigator && "getUserMedia" in navigator.mediaDevices;
    if (!deviceHasCamera) return;
    try {
      const permission = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      if (permission)
        setViewStates((prev) => ({ ...prev, cameraIsOpen: true }));
    } catch (error) {
      setErrorInfo({ show: true, text: "دسترسی غیرفعال است" });
    }
  };
  const handleSelectChain = (ctx) => {
    if (ctx["can_withdraw"]) {
      dispatch({ type: "SELECT_CHAIN", payload: ctx });
    }
  };
  const handleToggleNetworkModal = (value) => {
    return dispatch({ type: "CHANGE_MODAL_NETWORK", payload: value });
  };

  const handleRequestRefershPage = () => {
    return dispatch({ type: "REFRESH" });
  };

  const chainAttachedSymbol = useMemo(() => {
    if (state["selectedChain"]?.chain) {
      if (state["selectedChain"].chain === state["asset"]["symbol"]) {
        return state["asset"]["symbol"];
      }
      return state["asset"]["symbol"] + '-' + state["selectedChain"].chain;
    }
    return null;
  }, [state["selectedChain"]]);

  //# Check Details For Withdrawal ##
  const handleCheckWithdrawal = () => {
    const sumErros = [];
    if (
      userPayments.isFetched &&
      !userPayments.documentStatus &&
      userPayments.rialPayment
    )
      sumErros.push("document");
    if (!state["gaCode"]) sumErros.push("gaCode");
    if (!viewStates["amount"]) sumErros.push("amount");
    if (!viewStates["wallet"]) sumErros.push("wallet");
    if (state["selectedChain"]?.["chain"]) {
      if (
        +viewStates["amount"] < +state["selectedChain"]["withdraw_least_amount"]
      ) {
        sumErros.push("minAmount");
      }
      const userBalance = parseFloat(
        state["asset"]["balance"] - state["asset"]["locked"]
      );
      const symbolWthSumFee =
        parseFloat(viewStates["amount"]) +
        parseFloat(state["selectedChain"]["withdraw_tx_fee"]);
      if (symbolWthSumFee > userBalance) {
        sumErros.push("checkFee");
      }
    } else {
      sumErros.push("network");
    }
    if (
      Number(viewStates["amount"]) >
      parseFloat(state["asset"]["balance"] - state["asset"]["locked"])
    ) {
      sumErros.push("yourBalance");
    }
    if (sumErros[0]) {
      setWithdrawalErrors(
        sumErros.map((c) => {
          return EXCEPTEC_ERRORS_WIH[c];
        })
      );
    } else {
      setOpenTwoFacCode(true);
    }
  };

  const handleResetErrors = () => {
    setWithdrawalErrors([]);
  };

  const ShowFeeError = useMemo(() => {
    // if(state["asset"]["balance"] - state["asset"]["locked"] > viewStates["amount"] &&})
    if (viewStates["amount"]) {
      if (state["selectedChain"]?.["chain"]) {
        if (
          Number(viewStates["amount"]) +
            Number(state["selectedChain"]["withdraw_tx_fee"]) >
          parseFloat(state["asset"]["balance"] - state["asset"]["locked"])
        ) {
          return true;
        }
      }
    }
    return false;
  }, [viewStates, state]);

  return (
    <>
      <Stack>
        <Box mb={1.5}>
          <InputViaButton
            buttonTitle="کل موجودی"
            placeholder="مقدار برداشتی"
            type="number"
            onClickButton={onRequestAllBalance}
            onChange={(e) => onUpdateViewStates(e, "amount")}
            value={viewStates["amount"]}
          />
          {ShowFeeError && (
            <Box
              sx={{
                color: "var(--danger-color)",
                direction: "rtl",
                fontSize: "13px",
              }}
              mt={0.5}
            >
              مقدار کارمزد را از مقدار برداشت خود کم کنید.
            </Box>
          )}
        </Box>
        <Box mb={1.5}>
          <div
            className={styles.selectNetworkBox}
            onClick={() => handleToggleNetworkModal(true)}
          >
            <span>نوع شبکه</span>
            <span>
              {state["selectedChain"]?.chain ? (
                <>
                  {state["selectedChain"].chain}
                  <span className={styles.fee}>
                    (Fee: {state["selectedChain"].withdraw_tx_fee})
                  </span>
                </>
              ) : null}
            </span>
          </div>
        </Box>
        <Box mb={1.5}>
          <InputViaTakePic
            type="text"
            onClickTakePic={onRequestTakePic}
            onClickPaste={onRequestPaste}
            placeholder="آدرس کیف پول"
            onChange={(e) => onUpdateViewStates(e, "wallet")}
            value={viewStates["wallet"]}
          />
        </Box>
        {state["selectedChain"].chain &&
          state["hasTag"].includes(chainAttachedSymbol) && (
            <Box mb={1.5}>
              <InputViaButton
                placeholder={`آدرس ${state["tagNames"]?.[chainAttachedSymbol]}`}
                type="text"
                buttonTitle="چسباندن"
                onClickButton={onRequestPasteTag}
                onChange={(e) => onUpdateViewStates(e, "tag")}
                value={viewStates["tag"]}
              />
              <Box mt={0.5}>
                <span className={styles.notificationError}>
                  دقت کنید در صورتی که کیف مقصد تگ و یا ممو دارد حتما آنرا ثبت
                  کنید
                </span>
              </Box>
            </Box>
          )}
        <ul className={styles.overNotifContainer}>
          <li>
            دقت کنید برداشت ارز فقط در شبکه صحیح انجام شود. ارزفی هیچگونه
            مسيولیتی در قبال از دست رفتن سرمایه شما ندارد
          </li>
          <li>
            پس از تایید شما ارز به شبکه تحویل داده شد و باید منتظر دریافت تاییده
            در شبکه ماند
          </li>
        </ul>
        <Box mb={3}>
          <PaperButton
            type="success"
            style={{ zIndex: "2", height: "40px" }}
            onClick={handleCheckWithdrawal}
          >
            برداشت
          </PaperButton>
        </Box>
      </Stack>
      {viewStates["cameraIsOpen"] && (
        <div className={styles.qrCodeScanContainer}>
          <button className={styles.closeCamera} onClick={onRequestCloseCamera}>
            {appIcons("closeIcon")}
          </button>
          <QrReader
            constraints={{
              facingMode: "environment",
            }}
            onResult={(result, error) => {
              if (!!result) {
                handleQrCodeReaded(result);
              }
            }}
            className={styles.qrCodeScanner}
          />
        </div>
      )}
      <Snackbar
        open={errorInfo["show"]}
        autoHideDuration={2500}
        onClose={handleCloseSnackError}
      >
        <Alert
          sx={{ width: 1, direction: "rtl", fontFamily: "inherit" }}
          icon={<ErrorOutlineOutlined className="no-svg-change" />}
          severity="error"
        >
          {errorInfo["text"]}
        </Alert>
      </Snackbar>
      <BottomModal
        visible={state["networkModal"]}
        onRequestClose={() => handleToggleNetworkModal(false)}
      >
        <div className={styles.chainsModalAndInfoContainer}>
          <p className={styles.titleChainModal}>
            <span>شبکه انتقال را انتخاب کنید. </span>
            <span>توجه کنید که آدرس مقصد بر روی شبکه انتخابی باشد.</span>
          </p>
          <div className={styles.chainsContainer}>
            {state["chains"].map((ctx, idx) => {
              return (
                <div
                  className={styles.chainItem}
                  data-active={ctx["can_withdraw"]}
                  data-selected={ctx["chain"] === state["selectedChain"]?.chain}
                  key={ctx["chain"] + idx}
                  onClick={() => handleSelectChain(ctx)}
                >
                  <span className={styles.name}>
                    {ctx["chain"]}{" "}
                    {!ctx["can_withdraw"] ? <span>(غیر فعال)</span> : null}
                  </span>
                  <div className={styles.amounts}>
                    <span className={styles.list}>
                      کارمزد :{ctx["withdraw_tx_fee"]}{" "}
                      <span className={styles.symbol}>
                        {state["asset"]["symbol"]}
                      </span>
                    </span>
                    <span className={styles.list}>
                      حداقل برداشت: {ctx["withdraw_least_amount"]}{" "}
                      <span className={styles.symbol}>
                        {state["asset"]["symbol"]}
                      </span>
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </BottomModal>
      {state["isError"] && (
        <MessageModal type="error" title="مشکل در دریافت اطلاعات">
          <Stack sx={{ direction: "rtl" }}>
            <p style={{ textAlign: "center", marginBottom: 15, fontSize: 15 }}>
              مشکلی در دریافت اطلاعات وجود دارد, اینترنت خود را چک کنید یا با
              پشتیبانی ارتباط برقرار کنید.
            </p>
            <Stack flexDirection="row" columnGap={1}>
              <PaperButton onClick={handleRequestRefershPage}>
                تلاش مجدد
              </PaperButton>
              <PaperButton
                type="error"
                onClick={() => history.replace("/my-wallet")}
              >
                بازگشت
              </PaperButton>
            </Stack>
          </Stack>
        </MessageModal>
      )}
      {!isEmpty(withdrawalErrors) && (
        <MessageModal type="error" title="ورودی های نامعتبر">
          <ol className={styles.withdrawErrorContainer}>
            {withdrawalErrors.map((ctx, idx) => {
              return <li key={idx}>{ctx}</li>;
            })}
          </ol>
          <div className={styles.btnResetErrors}>
            <PaperButton onClick={handleResetErrors}>متوجه شدم</PaperButton>
          </div>
        </MessageModal>
      )}
      {openTwoFacCode && (
        <Confirm2FaCode
          state={state}
          dispatch={dispatch}
          viewStates={viewStates}
          setViewStates={setViewStates}
          setOpen={setOpenTwoFacCode}
          external={true}
        />
      )}
    </>
  );
}

export default ExternalTransferView;
