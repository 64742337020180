import styles from "./HeaderT.module.scss";
//* -- Icons
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import { Helmet } from "react-helmet";
import { useContext, useEffect, useMemo, useState } from "react";
import { tradesCTX } from "../../../context/tradesCTX";
import { useDispatch, useSelector } from "react-redux";
import { numFormatter } from "../../../utils/priceFunc";
import { addFavoriteCoins } from "../../../actions/favoriteCoins";
import PercentTrade from "../../MarketTradeLayout/PrecentTrade";
import { addCommaWithRialPrice } from "../../../utils/addComma";
import appIcons from "../../../utils/appIcons";
import { Grid } from "@mui/material";
import { useHistory } from "react-router";
import { isEmpty } from "lodash";
import { formatPrice } from "../../../utils/number.utils";
export default function HeaderT() {
  const MarketContext = useContext(tradesCTX);
  const { currentTrade, setAsideStatus, asideStatus } = MarketContext;
  
  const dealsReducer = useSelector((state) => state.dealsReducer);
  const lastOffer = useMemo(() => {
    if (!isEmpty(dealsReducer) && !isEmpty(currentTrade)) {
      const symbol = currentTrade.market;
      return formatPrice(dealsReducer[symbol][0].price).number;
    }
    return 0;
  }, [dealsReducer]);

  const dispatch = useDispatch();
  const history = useHistory();
  const favUserCoins = useSelector((state) => state.favCoins);



  const marketWSInfo = useSelector((state) => state.stateReducer);
  const [checkLastPrice, setCheckLastPrice] = useState(0);
  const [lastPriceCss, setLastPriceCss] = useState("");

  const ASSETS = useSelector((state) => state.market?.data);
  const PERCENT24H =
    ASSETS?.filter((c) => c.symbol === currentTrade?.asset)[0]
      ?.percent_change_24h ?? 0;

  const isIRT = useMemo(() => {
    return currentTrade?.area_title === "IRT";
  }, [currentTrade]);
  useEffect(() => {
    if (lastOffer) {
      if (+checkLastPrice >= +lastOffer) {
        setLastPriceCss("color_red");
      } else {
        setLastPriceCss("color_green");
      }
      setCheckLastPrice(lastOffer);
    }
  }, [lastOffer]);

  const showNumbers = (num) => {
    if (isIRT) {
      return addCommaWithRialPrice(num);
    }
    return num;
  };

  const handleBackRoute = () => {
    history.go(-1);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          خرید و فروش و معامله حرفه ای ارز دیجیتال{" "}
          {currentTrade?.asset_title_fa ?? "-"}/
          {currentTrade?.area_title_fa ?? "-"} (
          {currentTrade?.market ?? "BTCUSDT"}) - ارزفی
        </title>
        <meta
          name="description"
          content={`با مارکت حرفه ای ارزفی از دنیای حرفه ای ترید و معامله عقب نمانید
            انتخاب انواع روش های ترید و معامله ارز بیت کوین (${
              currentTrade?.market ?? "BTCUSDT"
            }}) از جمله: معاملات استاپ و لیمیت و خرید لحظه ای
            در سایت حرفه ای و مطمئن ارزفی
            قیمت لحظه ای ${currentTrade?.market ?? "BTCUSDT"}: ${
            lastOffer ?? "0"
          }$`}
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="Arzfi.com" />
        <meta name="twitter:creator" content="@ArzfiEx" />
        <meta property="og:type" content="website" />
        <meta
          name="keywords"
          content={`ارز دیجیتال, کیف پول, صرافی ایرانی, بهترین صرافی, معامله اسپات, مارکت حرفه ای, بیتکوین,اتریوم,خرید ارز دیجیتال, فروش ارز پیجیتال, ${
            currentTrade?.market ?? "BTCUSDT"
          }, ${currentTrade?.asset_title_fa ?? "بیت کوین"}`}
        />
      </Helmet>
      <div className={` ${styles.container}`}>
        <div className={styles.top}>
          <div className={styles.fake} onClick={handleBackRoute}>
            {appIcons("ArrowBackIcon")}
          </div>
          <div
            className={styles.market}
            onClick={() => setAsideStatus(!asideStatus)}
          >
            <div className={styles.arrow}>{appIcons("ArrowMoreIcon")}</div>
            <div className={styles.name}>
              <h1 className="change_color_to_E1E2E4">
                {currentTrade?.asset} / {currentTrade?.area_title}
              </h1>
            </div>
          </div>
          <div className={styles.isFavorite}>
            {favUserCoins.includes(currentTrade?.asset_title) ? (
              <StarIcon
                fontSize="inherit"
                className={`no-svg-change ${styles.Liked}`}
                onClick={() =>
                  dispatch(addFavoriteCoins(currentTrade.asset_title))
                }
              />
            ) : (
              <StarBorderIcon
                fontSize="inherit"
                className={styles.NoLike}
                onClick={() =>
                  dispatch(addFavoriteCoins(currentTrade.asset_title))
                }
              />
            )}
          </div>
        </div>
        <div className={`changeBackgroundColorToHardBlack ${styles.bottom}`}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            className={styles.summary}
          >
            <Grid item xs={4}>
              <div className={styles.title}>بالاترین (24h)</div>
              <div
                className={`${styles.desc} change_color_to_E1E2E4 eng-number-font`}
              >
                {showNumbers(marketWSInfo[currentTrade?.market]?.high ?? 0)}
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={styles.title}>مقدار ({currentTrade?.asset})</div>
              <div
                className={`${styles.desc} change_color_to_E1E2E4 eng-number-font`}
              >
                {numFormatter(+marketWSInfo[currentTrade?.market]?.volume ?? 0)}
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={styles.title}>پایین ترین (24h)</div>
              <div
                className={`${styles.desc} change_color_to_E1E2E4 eng-number-font`}
              >
                {showNumbers(marketWSInfo[currentTrade?.market]?.low ?? 0)}
              </div>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={4}>
              <PercentTrade />
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={6}>
              <div className={styles.price}>
                <div className={` ${styles.market_price} eng-number-font`}>
                  <span className={lastPriceCss}>
                    {showNumbers(lastOffer)}{" "}
                  </span>{" "}
                  <span
                    className={`${styles.area_title} change_color_to_E1E2E4`}
                  >
                    {currentTrade?.area_title}
                  </span>
                  <span
                    className={`eng-number-font ${
                      PERCENT24H >= 0 ? "color_green " : "color_red"
                    }`}
                  >
                    {" "}
                    {PERCENT24H}%
                  </span>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}
