import { useEffect, useMemo, useState } from "react";
import { useParams, useHistory } from "react-router";
import styles from "./BuySell.module.scss";
import { isEmpty } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { getCoinDetail } from "../../../actions/getCoinDetail";
import {
  hiddenLoadingPage,
  showLoadingPage,
} from "../../../actions/loadingPage";
import ErrorMessageHook from "../../../helpers/errors_notifications/ErrorMessage";
import Num2persian from "num2persian";
import {
  num2English,
  showPrice,
  showUserBalance,
  toFixedE,
} from "../../../utils/priceFunc";
import { userCoinBalance } from "../../../actions/user";
import {
  coinUsersBalance,
  FetchMakeMarketOrder,
} from "../../../services/userServices";
import { usersMakeOrders } from "../../../services/adminServices";
import { errorMessage, successMessage } from "../../../utils/messages";
import LayoutCoin from "./LayoutCoin";
//Icons
import copyIcon from "../../../styles/icon/CopyIcon2.svg";
import VerticalIcon from "../../../styles/icon/menu/Vertical_switch.svg";
import ConfirmModal from "../../Modals/ConfirmModal";
import { Stack, Typography } from "@mui/material";
import MuiInput from "./MuiInput";
import {
  NumberHasDecimal,
  showBetterPrice,
} from "../../../utils/showBetterPrice";
import EXECEPTED_ERRORS from "./EXCEPTED_ERROS";
import { DangerMuiBtn, SuccessMuiBtn } from "../../MuiButtons";

const TYPE_MARKET = {
  IRT: "تومان",
  USDT: "تتر",
};

let TIMER_FORCE_UPDATE_PRICEES;
function showBetterPriceUsed(num) {
  let thisNum = num;
  if (isNaN(thisNum)) thisNum = 0;
  const prettier = showBetterPrice(thisNum);
  if (thisNum.toString().includes("e")) {
    const newNum = toFixedE(thisNum);
    return newNum;
  }
  return prettier;
}
export default function SellCoin() {
  const history = useHistory();
  const { name } = useParams();
  const dispatch = useDispatch();

  const [disabledBtn, setDisabledBtn] = useState(false);
  const [switchButton, setSwitchButton] = useState(false);
  const [coinValue, setCoinValue] = useState("");
  const [priceValue, setPriceValue] = useState("");
  const [messageBox, setMessageBox] = useState("");
  const [coinUserBalance, setCoinUserBalance] = useState({});
  const [mustBeUpdate, setMustBeUpdate] = useState({
    lastEdit: "",
    timer: 0,
  });
  const [tradeError, setTradeError] = useState({});
  const [onPrice, setOnPrice] = useState(0);
  const [feePrice, setFeePrice] = useState(0);
  //State's
  const COIN_DATA = useSelector((state) => state.coinInfo.data);
  const depthReducer = useSelector((state) => state.depthReducer);
  const userId = useSelector((state) => state.userId);
  const userAreaDetails = useSelector(
    (state) => state.fullUserInfo.coinBalance
  );
  const marketListReducer = useSelector((state) => state.tradesList);
  const socketReducer = useSelector((state) => state.stateReducer);
  //End State's
  const [openConfrimModal, setOpenConfirmModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  //# ---> New
  //# Check Type of Trade This Coin ## IRT Or USDT
  const AREA_TYPE = useMemo(() => {
    const searchArray = history.location.search.split("?");
    if (searchArray[1]) {
      const TypeIsExist = searchArray[1].split("type=");
      switch (TypeIsExist[1].toUpperCase()) {
        case "IRT":
          return "IRT";
        case "USDT":
          return "USDT";
        default:
          return "IRT";
      }
    }
    return "IRT";
  }, [history.location]);

  // # # # # # #
  function toggleError({ type, id, code }) {
    if (type === "add") {
      setTradeError((prev) => {
        return {
          ...prev,
          [id]: EXECEPTED_ERRORS[code],
        };
      });
    } else {
      setTradeError((prev) => {
        return {
          ...prev,
          [id]: { error: false },
        };
      });
    }
  }
  // # # # # # #
  // Coin Status From Socket
  const currentCoinSocket = useMemo(() => {
    if (!isEmpty(COIN_DATA)) {
      return socketReducer[COIN_DATA.symbol + AREA_TYPE] || {};
    } else {
      return {};
    }
  }, [socketReducer, AREA_TYPE, COIN_DATA]);
  //# Coin Detail From API
  const currentCoinAPIList = useMemo(() => {
    if (marketListReducer.hasOwnProperty("list") && !isEmpty(COIN_DATA)) {
      return (
        marketListReducer.list.filter(
          (c) => c.market === COIN_DATA.symbol + AREA_TYPE
        )[0] || {}
      );
    } else {
      return {};
    }
  }, [COIN_DATA, AREA_TYPE, marketListReducer]);
  //# User Area Balances
  const USER_AREA_BALANCE = useMemo(() => {
    if (!isEmpty(userId) || userId.id !== "default") {
      if (userAreaDetails && userAreaDetails.hasOwnProperty("asset")) {
        const CanUseAreaBalance =
          Number(userAreaDetails.asset.balance) -
          Number(userAreaDetails.asset.locked);
        return showUserBalance(CanUseAreaBalance);
      }
    } else {
      return 0;
    }
  }, [AREA_TYPE, userId, userAreaDetails]);

  //# Switch Button Detector
  const handleSwitchButton = () => {
    if (!switchButton) {
      setMustBeUpdate((prev) => ({ ...prev, lastEdit: "usdt" }));
    } else {
      setMustBeUpdate((prev) => ({ ...prev, lastEdit: "coin" }));
    }
    setSwitchButton((prev) => !prev);
  };
  //# <--- NEW
  ///// CALCULATOR
  const handleChangeAreaPriceValues = (e) => {
    if (AREA_TYPE === "IRT") {
      const calc = num2English(e.target.value)?.replace(/\D/g, "");
      setPriceValue(calc);
      setCoinValue(calc / COIN_DATA?.arzfi_buy_price);
      return;
    } else if (AREA_TYPE === "USDT") {
      return handleChangeUSDTPrice(e.target.value);
    }
    return;
  };
  const changeCoinPrice = (e) => {
    if (AREA_TYPE === "IRT") {
      const calc = num2English(e.target.value)?.match(/\d|\./g)?.join("") || "";
      setCoinValue(calc);
      const rialValue = calc * +COIN_DATA?.arzfi_buy_price;
      setPriceValue(rialValue.toString()?.split(".")[0]);
    } else {
      handleChangeCoinAmount(e.target.value);
    }
  };

  //### Functions To Handle Balance
  const handleChangeUSDTPrice = (num) => {
    setMustBeUpdate({ lastEdit: "usdt", timer: 5000 });
    let AMOUNT = num;
    if (AMOUNT < 0) AMOUNT = 0;
    setPriceValue(AMOUNT);
    let PriceInDepth = 0;
    let CanTradeDepth = [];
    if (depthReducer[name + "USDT"]) {
      CanTradeDepth = depthReducer[name + "USDT"].buyers.filter((c) => {
        const PAYED = AMOUNT / c.value;
        if (c.price > PAYED) {
          return c;
        }
      });
    }
    if (CanTradeDepth[0]) {
      PriceInDepth = CanTradeDepth[0].price;
      toggleError({ type: "remove", id: 201 });
      setOnPrice(CanTradeDepth[0].price);
    } else {
      PriceInDepth = 0;
      toggleError({ type: "add", id: 201, code: 3 });
      setOnPrice(0);
    }
    let ExchangeFee = (currentCoinAPIList.fee_rate * AMOUNT) / PriceInDepth;
    let another = AMOUNT / PriceInDepth + ExchangeFee;
    if (another.toString().includes("e")) {
      another = 0;
    }
    another = showBetterPrice(another);
    if (another.toString().includes("e") || another < 0) {
      another = 0;
    } else {
      if (!(another % 2)) {
        another = Math.ceil(another);
      }
    }
    setFeePrice(currentCoinAPIList.fee_rate * Number(another) * PriceInDepth);
    setCoinValue(another);
  };
  //#
  const handleChangeCoinAmount = (num) => {
    setMustBeUpdate({ lastEdit: "coin", timer: 5000 });
    let AMOUNT = num;
    if (AMOUNT < 0) AMOUNT = 0;
    setCoinValue(AMOUNT);
    let PriceInDepth = 0;
    let CanTradeDepth = [];
    if (depthReducer[name + "USDT"]) {
      CanTradeDepth = depthReducer[name + "USDT"].buyers.filter((c) => {
        if (c.value > AMOUNT) {
          return c;
        }
      });
    }

    if (CanTradeDepth[0]) {
      PriceInDepth = CanTradeDepth[0].price;
      toggleError({ type: "remove", id: 201 });
      setOnPrice(CanTradeDepth[0].price);
    } else {
      PriceInDepth = 0;
      toggleError({ type: "add", id: 201, code: 3 });
      setOnPrice(0);
    }
    let ExchangeFee = currentCoinAPIList.fee_rate * AMOUNT * PriceInDepth;
    setFeePrice(ExchangeFee);
    let another = Number(AMOUNT * PriceInDepth) - ExchangeFee;
    if (another.toString().includes("e")) {
      another = 0;
    } else {
      let hasDec = NumberHasDecimal(another);
      if (hasDec) {
        another = another.toFixed(2);
        if (!(another % 2)) {
          another = Math.ceil(another);
        }
      }
    }
    setPriceValue(another);
  };
  //## Handle Update Price After User Not Activiy To Change
  useEffect(() => {
    let mounted = true;
    clearTimeout(TIMER_FORCE_UPDATE_PRICEES);
    if (
      mounted &&
      mustBeUpdate.timer !== 0 &&
      priceValue &&
      AREA_TYPE === "USDT"
    ) {
      TIMER_FORCE_UPDATE_PRICEES = setTimeout(() => {
        if (mustBeUpdate.lastEdit === "usdt") {
          handleChangeUSDTPrice(priceValue);
        } else {
          handleChangeCoinAmount(coinValue);
        }
      }, mustBeUpdate.timer);
    }
    return () => {
      clearTimeout(TIMER_FORCE_UPDATE_PRICEES);
      mounted = false;
    };
  }, [mustBeUpdate, AREA_TYPE]);
  //###
  ///// End Calculator
  useEffect(() => {
    let mounted = true;
    const getThisCoinInfo = async () => {
      try {
        await dispatch(getCoinDetail(name));

        dispatch(hiddenLoadingPage());
      } catch (ex) {
        dispatch(showLoadingPage());
      }
    };
    //
    (async () => {
      dispatch(showLoadingPage());
      if (mounted) {
        await getThisCoinInfo();
      }
    })();
    //
    const reloadInfo = setInterval(() => {
      getThisCoinInfo();
    }, 15000);
    return () => {
      clearInterval(reloadInfo);
      mounted = false;
    };
  }, [name]);

  useEffect(() => {
    let mounted = true;
    if (mounted && AREA_TYPE && !isEmpty(userId) && userId.id !== "default") {
      dispatch(userCoinBalance(AREA_TYPE.toLocaleLowerCase()));
      getUserThisCoinBalance();
    }
    return () => {
      mounted = false;
    };
  }, [AREA_TYPE, userId]);

  useEffect(() => {
    return () => {
      setCoinValue("");
      setPriceValue("");
      setTradeError({});
      setMustBeUpdate({ lastEdit: "", timer: 0 });
    };
  }, [history.location]);
  const getUserThisCoinBalance = async () => {
    const { data } = await coinUsersBalance(name);
    setCoinUserBalance(data);
  };
  const choiceAllCoins = () => {
    let amount = 0;
    if (coinUserBalance && coinUserBalance.hasOwnProperty("asset")) {
      amount = coinUserBalance.asset.balance - coinUserBalance.asset.locked;
    }
    if (AREA_TYPE === "IRT") {
      setCoinValue(amount);
      const rialValue = amount * +COIN_DATA?.arzfi_buy_price;
      setPriceValue(rialValue?.toString()?.split(".")[0]);
    } else {
      handleChangeCoinAmount(amount);
    }
  };

  //#
  const handleSubmitTrade = (e) => {
    if (AREA_TYPE === "IRT") {
      return handleBuyCoin(e);
    } else if (AREA_TYPE === "USDT") {
      e.preventDefault();
      return handleCommitSellMarket();
    }
    return errorMessage(`مشکلی در انجام عملیات رخ داده! UNSYNC`);
  };
  const handleBuyCoin = async (event) => {
    if (event.type === "submit") {
      event.preventDefault();
    }
    if (!+coinValue)
      return setMessageBox("تعداد یا مبلغ فروش به درستی وارد نشده است.");
    if (+coinValue < +COIN_DATA.minSellAmount)
      return setMessageBox(
        `حداقل مقدار فروش ${COIN_DATA.faName} ${COIN_DATA.minSellAmount}`
      );
    if (+coinValue > +COIN_DATA.maxSellAmount)
      return setMessageBox(
        `حداکثر مقدار فروش ${COIN_DATA.faName} ${COIN_DATA.maxSellAmount}`
      );
    if (
      Number(coinUserBalance.asset.balance - coinUserBalance.asset.locked) <
      +coinValue
    )
      return setMessageBox("موجودی حساب شما کافی نمیباشد");
    let body;
    if (event === true) {
      body = {
        asset: name,
        confirmed: true,
        orderAmount: coinValue,
        orderType: "sell",
      };
    } else {
      body = {
        asset: name,
        orderAmount: coinValue,
        orderType: "sell",
      };
    }
    try {
      setDisabledBtn(true);
      const { data } = await usersMakeOrders(body);
      if (data.needConfirmation) {
        setOpenConfirmModal(true);
        setModalMessage(data.error);
        return;
      }
      if (data.error) {
        errorMessage(data.error);
      } else {
        successMessage(data.message);
        setPriceValue(0);
        setCoinValue(0);
        dispatch(userCoinBalance("irt"));
        getUserThisCoinBalance();
      }
    } catch (ex) {
      errorMessage("دوباره تلاش کنید");
    } finally {
      setDisabledBtn(false);
    }
  };

  //# Send Data For Get By USDT Price ###
  const handleCommitSellMarket = async () => {
    if (tradeError[201]) {
      if (tradeError[201].error) {
        return errorMessage(`این مقدار برای فروش در بازار موجود نمیباشد`);
      }
    }
    if (!coinValue) return errorMessage(`تعداد برای فروش را وارد کنید.`);
    let userCoins =
      coinUserBalance.asset.balance - coinUserBalance.asset.locked;
    if (Number(coinValue) > Number(userCoins))
      return errorMessage(`موجودی ${COIN_DATA?.symbol} شما کافی نمیباشد.`);
    try {
      setDisabledBtn(true);
      const { data } = await FetchMakeMarketOrder(
        JSON.stringify({
          market: currentCoinAPIList.market,
          orderType: "sell",
          orderAmount: coinValue,
        })
      );
      if (data.error) {
        errorMessage(data.error);
      }
      if (data.message) {
        setMustBeUpdate({ timer: 0, lastEdit: "" });
        setCoinValue("");
        setPriceValue("");
        successMessage(data.message);
        let timerDelay = setTimeout(() => {
          dispatch(userCoinBalance("usdt"));
          getUserThisCoinBalance();
          clearTimeout(timerDelay);
        }, 1500);
      }
    } catch (ex) {
      errorMessage("مشکلی از سمت سرور پیش آمده است.");
    } finally {
      setDisabledBtn(false);
    }
  };

  const handleSwitchSell = (e) => {
    e.preventDefault();
    return history.replace(`/buy/${COIN_DATA["symbol"]}?type=${AREA_TYPE}`);
  };
  return (
    <LayoutCoin>
      {messageBox && (
        <ErrorMessageHook
          setMessageBox={setMessageBox}
          messageBox={messageBox}
        />
      )}
      <div>
        <form onSubmit={handleSubmitTrade}>
          {switchButton ? (
            <>
              <Stack width="93%" margin="0 auto" marginTop="10px">
                <MuiInput
                  endAd={`مبلغ کل(${TYPE_MARKET[AREA_TYPE]})`}
                  fullWidth
                  onChange={handleChangeAreaPriceValues}
                  value={isNaN(priceValue) ? "" : priceValue}
                  inputMode="decimal"
                />
              </Stack>
              {AREA_TYPE === "IRT" && priceValue && (
                <div
                  className={`font_color_white ${styles.show_persian_price}`}
                >
                  {Num2persian(priceValue)} تومان
                </div>
              )}
              <div className={styles.user_wallet_amount}>
                <div className={`font_color_white ${styles.title_count}`}>
                  موجودی کیف پول شما:
                </div>
                <div className={`font_color_white ${styles.user_coins}`}>
                  <div className={styles.symbol_coin}>
                    {TYPE_MARKET[AREA_TYPE]}
                  </div>
                  <div className={`eng-number-font ${styles.coin_amount}`}>
                    {USER_AREA_BALANCE}
                  </div>
                </div>
              </div>
              {AREA_TYPE !== "IRT" && (
                <Stack className={styles.on_price_container}>
                  <Stack>کارمزد: </Stack>
                  <Stack className="eng-number-font">
                    {isNaN(feePrice) ? 0 : feePrice.toFixed(2)} تتر
                  </Stack>
                </Stack>
              )}
              <div className={styles.switch_container}>
                <div
                  className={styles.button_change_to_sell}
                  onClick={handleSwitchButton}
                >
                  <img src={VerticalIcon} alt="" width={25} height={25} />
                </div>
              </div>
              <Stack width="93%" margin="0 auto" marginTop="10px">
                <MuiInput
                  endAd={
                    <Typography fontSize="15px">
                      مقدار {COIN_DATA?.symbol} ≈
                    </Typography>
                  }
                  fullWidth
                  disabled
                  value={isNaN(coinValue) ? "" : showPrice(coinValue)}
                />
              </Stack>
              <div
                className={styles.user_coin_count}
                onClick={() => choiceAllCoins()}
              >
                <div className={`font_color_white ${styles.title_count}`}>
                  موجودی کیف پول شما:
                </div>
                <div className={`font_color_white ${styles.user_coins}`}>
                  <div className={styles.symbol_coin}>
                    <img
                      src={copyIcon}
                      alt="bbb"
                      width={14}
                      height={14}
                      className="convertPicToGreen"
                    />{" "}
                    {COIN_DATA?.symbol}
                  </div>
                  <div className={`eng-number-font ${styles.coin_amount}`}>
                    {coinUserBalance?.asset &&
                      +coinUserBalance.asset.balance -
                        +coinUserBalance.asset.locked}
                  </div>
                </div>
              </div>
              {AREA_TYPE !== "IRT" && (
                <Stack className={styles.on_price_container}>
                  <Stack>میانگین قیمت خرید: </Stack>
                  <Stack className="eng-number-font">
                    {showBetterPriceUsed(onPrice || currentCoinSocket?.last)}{" "}
                    تتر
                  </Stack>
                </Stack>
              )}
            </>
          ) : (
            <>
              <Stack width="93%" margin="0 auto">
                <MuiInput
                  endAd={
                    <Typography fontSize="15px">
                      مقدار {COIN_DATA?.symbol} ≈
                    </Typography>
                  }
                  fullWidth
                  placeholder={COIN_DATA?.minSellAmount}
                  onChange={changeCoinPrice}
                  value={coinValue}
                  inputMode="decimal"
                />
              </Stack>
              <div className={styles.user_coin_count}>
                <div className={`font_color_white ${styles.title_count}`}>
                  موجودی کیف پول شما:
                </div>
                <div
                  className={`font_color_white ${styles.user_coins}`}
                  onClick={() => choiceAllCoins()}
                >
                  <div className={styles.symbol_coin}>
                    <img
                      src={copyIcon}
                      alt="bbb"
                      width={14}
                      height={14}
                      className="convertPicToGreen"
                    />{" "}
                    {COIN_DATA?.symbol}
                  </div>
                  <div className={`eng-number-font ${styles.coin_amount}`}>
                    {coinUserBalance?.asset &&
                      +coinUserBalance.asset.balance -
                        +coinUserBalance.asset.locked}
                  </div>
                </div>
              </div>
              {AREA_TYPE !== "IRT" && (
                <Stack className={styles.on_price_container}>
                  <Stack>میانگین قیمت خرید: </Stack>
                  <Stack className="eng-number-font">
                    {showBetterPriceUsed(onPrice || currentCoinSocket?.last)}{" "}
                    تتر
                  </Stack>
                </Stack>
              )}
              <div className={styles.switch_container}>
                <div
                  className={styles.button_change_to_sell}
                  onClick={handleSwitchButton}
                >
                  <img src={VerticalIcon} alt="" width={25} height={25} />
                </div>
              </div>
              <Stack width="93%" margin="0 auto" marginTop="10px">
                <MuiInput
                  endAd={`مبلغ کل(${TYPE_MARKET[AREA_TYPE]})`}
                  fullWidth
                  disabled
                  readOnly
                  value={priceValue === "NaN" ? "" : showPrice(priceValue)}
                />
              </Stack>
              {AREA_TYPE === "IRT" && priceValue && (
                <div
                  className={`font_color_white ${styles.show_persian_price}`}
                >
                  {Num2persian(priceValue)} تومان
                </div>
              )}
              <div className={styles.user_wallet_amount}>
                <div className={`font_color_white ${styles.title_count}`}>
                  موجودی کیف پول شما:
                </div>
                <div className={`font_color_white ${styles.user_coins}`}>
                  <div className={styles.symbol_coin}>
                    {TYPE_MARKET[AREA_TYPE]}
                  </div>
                  <div className={`eng-number-font ${styles.coin_amount}`}>
                    {USER_AREA_BALANCE}
                  </div>
                </div>
              </div>
              {AREA_TYPE !== "IRT" && (
                <Stack className={styles.on_price_container}>
                  <Stack>کارمزد: </Stack>
                  <Stack className="eng-number-font">
                    {isNaN(feePrice) ? 0 : feePrice.toFixed(2)} تتر
                  </Stack>
                </Stack>
              )}
            </>
          )}
          {tradeError[201] && tradeError[201].error && (
            <Stack className={styles.error_container}>
              {tradeError[201].message}
            </Stack>
          )}
          <div className={styles.button_place}>
            <Stack flex={1}>
              <SuccessMuiBtn onClick={handleSwitchSell} text="خرید" />
            </Stack>
            <Stack flex={2}>
              <DangerMuiBtn
                type="submit"
                disabled={isEmpty(userId) ? true : disabledBtn}
                text={`فروش ${COIN_DATA?.faName ?? "--"}`}
              />
            </Stack>
          </div>
        </form>
        {isEmpty(userId) ? (
          <div className={`font_color_white ${styles.message_for_login}`}>
            ابتدا وارد{" "}
            <span
              onClick={() => history.push("/authorization/login")}
              className="font_color_change_to_green"
            >
              حساب کاربری
            </span>{" "}
            خود شوید
          </div>
        ) : (
          ""
        )}
      </div>
      <ConfirmModal
        open={openConfrimModal}
        setOpen={setOpenConfirmModal}
        message={modalMessage}
        process={handleBuyCoin}
      />
    </LayoutCoin>
  );
}
