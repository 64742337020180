export function updateSocketStateAction(data) {
  return (dispatch) => {
    dispatch({ type: "UPDATE_STATE_SUBSCRIBE", payload: data });
  };
}
export function updateSocketDealsAction(data) {
  if (data)
    return (dispatch) => {
      dispatch({ type: "UPDATE_DEALS_SUBSCRIBE", payload: data });
    };
}
export function updateSocketDepthAction(data) {
  if (data)
    return (dispatch) => {
      dispatch({ type: "UPDATE_DEPTH_SUBSCRIBE", payload: data });
    };
}
