import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { tradesCTX } from "./tradesCTX";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
//* --> Services & API's & Actions
import { getTradeMarketList } from "../actions/getMarketList";
//* --> Components
import { LoadingPage } from "../components/LoadingPage/LoadingPage";

//* --> Action's && Services
import {
  coinUsersBalance,
  FetchMakeLimitOrder,
  FetchMakeMarketOrder,
  FetchMakeStopLimitOrder,
  FetchMakeStopMarketOrder,
} from "../services/userServices";
import { getUserMarketOrdersList } from "../actions/UsersMarketOrders";
import { GetUnexecutedOrders } from "../actions/UsersUnexecutedOrders";
import { GetUserStopOrdersList } from "../actions/UsersStopOrdersList";
//* --> Socket-IO

//* --> Utils
import { useHistory, withRouter } from "react-router";

//# Logo
import appLogo from "../styles/pics/logo.png";
import { Link } from "react-router-dom";
import STABLE_COINS from "../utils/stable_coins";
import ConfirmWithMessage from "../components/Modals/ConfirmWithMessage";
import useSocketConnection from "../hooks/useSocketConnection";

let DISABLE_MARKETS = [];
const DISABLE_USDT_STABLES = STABLE_COINS.map((symbol) => {
  return symbol + "USDT";
});
const DISABLE_IRT_STABLES = STABLE_COINS.map((symbol) => {
  return symbol + "IRT";
});
DISABLE_MARKETS = DISABLE_MARKETS.concat(
  DISABLE_USDT_STABLES,
  DISABLE_IRT_STABLES
);

function OrdersContext({ children, marketName }) {
  useSocketConnection(
    `state,depth.${marketName},deals.${marketName}`,
    undefined,
    true
  );

  const history = useHistory();
  const [currentTrade, setCurrentTrade] = useState([]);
  const [currentTradeUpdate, setCurrentTradeUpdate] = useState([]);

  const tradesMarketList = useSelector((state) => state.tradesList);
  const hasLoading = useSelector((state) => state.loadingBar?.loading);
  const isUserLogin = useSelector((state) => state.userId?.userHashCode);
  const [market, setMarket] = useState("");

  if (market !== marketName) {
    setCurrentTrade([]);
    setCurrentTradeUpdate([]);
    setMarket(marketName);
  }

  const LEGALL_MARKET = useMemo(() => {
    if (DISABLE_MARKETS.includes(market)) {
      return false;
    }
    return true;
  }, [market]);

  useLayoutEffect(() => {
    setCurrentTrade([]);
  }, [history.location.search]);


  const [marketType, setMarketType] = useState("limit");
  const [typeOfTrade, setTypeOfTrade] = useState("buy");
  const [menuStatusMarketType, setMenuStatusMarketType] = useState(false);
  const [sortListBy, setSortListBy] = useState("");
  const [showSortListMenu, setShowSortListMenu] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [callBackConfirm, setCallBackConfirm] = useState(null);
  //* --> Limit Slider's
  const [buyLimitSlider, setBuyLimitSlider] = useState(0);
  const [sellLimitSlider, setSellLimitSlider] = useState(0);
  //* --> Market Slider's
  const [buyMarketSlider, setBuyMarketSlider] = useState(0);
  const [sellMarketSlider, setSellMarketSlider] = useState(0);
  //* --> Stop-Limit Slider's
  const [buyStopLimitSlider, setBuyStopLimitSlider] = useState(0);
  const [sellStopLimitSlider, setSellStopLimitSlider] = useState(0);
  //* --> Stop-Market Slider's
  const [buyStopMarketSlider, setBuyStopMarketSlider] = useState(0);
  const [sellStopMarketSlider, setSellStopMarketSlider] = useState(0);

  //* --> State's for WebSocket
  const [lastTrades, setLastTrades] = useState([]);
  const [buyOffers, setBuyOffers] = useState([]);
  const [sellOffers, setSellOffers] = useState([]);
  const [sumAllOffers, setSumAllOffers] = useState([]);
  const [lastOffer, setLastOffer] = useState(0);
  const [marketWSInfo, setMarketWSInfo] = useState("");
  //* --> Choice the margin - merge
  const [merge, setMerge] = useState("");
  const [mergeList, setMergeList] = useState([]);
  const [refereshList, setRefreshList] = useState(0);
  const [percentChanges, setPercentChanges] = useState({ buy: 50, sell: 50 });
  const dispatch = useDispatch();

  //* --> Get Merge-List
  useEffect(() => {
    if (!isEmpty(currentTrade)) {
      let emptyArray = [];
      setMerge(currentTrade.default_merge);
      const splitMerge = currentTrade.merge.split(", ");
      splitMerge.map((m) => emptyArray.push({ value: m, title: m }));
      setMergeList(emptyArray);
    }
    return () => {
      clearStates();
    };
  }, [currentTrade]);

  useEffect(() => {
    return () => {
      clearSliders();
      setLastTrades([]);
      setSellOffers([]);
      setBuyOffers([]);
      setSumAllOffers([]);
      setLastOffer(0);
    };
  }, [currentTrade]);

  useEffect(() => {
    clearSliders();
    clearStates();
  }, [marketType]);

  //* Filters For Table's BOTH
  const [showThisMarketTable, setShowThisMarketTable] = useState(false);
  const [disabledCheckBoxTable, setDisabledCheckBoxTable] = useState(false);
  const [asideStatus, setAsideStatus] = useState(false);

  const [showThisMarketOpen, setShowThisMarketOpen] = useState(false);
  const [disabledCheckBoxOpen, setDisabledCheckBoxOpen] = useState(false);

  //* --> SET CURRENT MARKET -- This STATE => Maybe Some Where USED  - MAYBE NOT
  const [disableSuccessBtn, setDisableSuccessBtn] = useState(false);
  const [disableDangerBtn, setDisableDangerBtn] = useState(false);
  const [updateStopList, setUpdateStopList] = useState(false);

  //* --> User Asset's And Trading Area Balances
  const [assetBalance, setAssetBalance] = useState(0);
  const [areaBalance, setAreaBalance] = useState(0);
  //* <-- User Asset's And Trading Area Balances

  /* -Order type: Limit (State's) --> */
  /* ---Order Buy Limit -> beginning */
  const [orderPriceBuyLimit, setOrderPriceBuyLimit] = useState("");
  const [orderAmountBuyLimit, setOrderAmountBuyLimit] = useState("");
  /* ---Order Buy Limit <- end */

  /* ---Order Sell Limit -> beginning */
  const [orderPriceSellLimit, setOrderPriceSellLimit] = useState("");
  const [orderAmountSellLimit, setOrderAmountSellLimit] = useState("");
  /* ---Order Sell Limit <- end */

  /* -Order type: Stop-Limit (State's) --> */
  /* ---Order Buy Limit -> beginning */
  const [orderPriceBuyStopLimit, setOrderPriceBuyStopLimit] = useState("");
  const [orderBuyStopPrice, setOrderBuyStopPrice] = useState("");
  const [orderAmountBuyStopLimit, setOrderAmountBuyStopLimit] = useState("");
  /* ---Order Buy Limit <- end */

  /* ---Order Sell Limit -> beginning */
  const [orderPriceSellStopLimit, setOrderPriceSellStopLimit] = useState("");
  const [orderSellStopPrice, setOrderSellStopPrice] = useState("");
  const [orderAmountSellStopLimit, setOrderAmountSellStopLimit] = useState("");
  /* ---Order Sell Limit <- end */

  //* -Order type: Market-Price (State's)
  const [orderMarketAmount, setOrderMarketAmount] = useState("");
  const [orderMarketPrice, setOrderMarketPrice] = useState("");

  //* -Order type : Stop-Market (State's)
  const [orderBuyPriceStopMarket, setOrderBuyPriceStopMarket] = useState("");
  const [orderBuyAmountStopMarket, setOrderBuyAmountStopMarket] = useState("");

  const [orderSellPriceStopMarket, setOrderSellPriceStopMarket] = useState("");
  const [orderSellAmountStopMarket, setOrderSellAmountStopMarket] =
    useState("");

  //# Set Timer For Re-Validate Data
  useEffect(() => {
    dispatch(getTradeMarketList());
    const tradesInv = setInterval(() => {
      dispatch(getTradeMarketList());
    }, 350000);
    return () => clearInterval(tradesInv);
  }, []);
  //# End set Timer to Re-validate Data

  useEffect(() => {
    if (!isEmpty(tradesMarketList)) {
      let MarketSymbol = "";
      let MarketTypeSearched = "";
      if (marketName) {
        const _isIRT = marketName.slice(-3) === "IRT";
        const _isUSDT = marketName.slice(-4) === "USDT";
        if (_isUSDT) {
          MarketSymbol = marketName.split("USDT", 1)[0];
          MarketTypeSearched = "USDT";
        } else if (_isIRT) {
          MarketSymbol = marketName.split("IRT", 1)[0];
          MarketTypeSearched = "IRT";
        }
      }
      const _hasIRTMarket = tradesMarketList.list.filter((c) => {
        return c.market === MarketSymbol + "IRT";
      })[0];
      const _hasUSDTMarket = tradesMarketList.list.filter((c) => {
        return c.market === MarketSymbol + "USDT";
      })[0];
      if (isEmpty(currentTrade)) {
        console.log("Render-Times In useEffect currentTrade to SetTrade");
        if (marketName) {
          if (MarketTypeSearched === "USDT") {
            if (!isEmpty(_hasUSDTMarket)) {
              setCurrentTradeUpdate(_hasUSDTMarket);
              setCurrentTrade(_hasUSDTMarket);
            } else if (!isEmpty(_hasIRTMarket)) {
              toast.error("مارکت تتر فعال نمیباشد به ریالی منتقل شدید");
              history.replace(`/market/trade?market=${MarketSymbol + "IRT"}`);
            } else {
              toast.error(
                "مارکت این ارز فعال نمیباشد, به خرید سریع منتقل شدید."
              );
              history.replace(`/buy/${MarketSymbol}?type=IRT`);
            }
          } else {
            if (!isEmpty(_hasIRTMarket)) {
              setCurrentTradeUpdate(_hasIRTMarket);
              setCurrentTrade(_hasIRTMarket);
            } else if (!isEmpty(_hasUSDTMarket)) {
              toast.error("مارکت ریال فعال نمیباشد به مارکت تتر منتقل شدید");
              history.replace(`/market/trade?market=${MarketSymbol + "USDT"}`);
            } else {
              toast.error(
                "مارکت این ارز فعال نمیباشد, به خرید سریع منتقل شدید."
              );
              history.replace(`/buy/${MarketSymbol}?type=IRT`);
            }
          }
        } else {
          setCurrentTradeUpdate(tradesMarketList.list[1]);
          setCurrentTrade(tradesMarketList.list[1]);
        }
      }
    }
  }, [tradesMarketList, marketName]);
  //* --> Get Asset and TradeArea User Balance -->

  useEffect(() => {
    let mounted = true;
    let inerval;
    if (mounted) {
      if (!isEmpty(currentTrade)) {
        if (!isEmpty(isUserLogin)) {
          getNewAssets();
          inerval = setInterval(() => {
            getNewAssets();
          }, 80000);
        }
      }
    }
    return () => {
      mounted = false;
      clearInterval(inerval);
    };
  }, [currentTrade, isUserLogin]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (!isEmpty(isUserLogin) && !isEmpty(currentTrade)) {
        if (showThisMarketOpen) {
          try {
            setDisabledCheckBoxOpen(true);
            dispatch(GetUserStopOrdersList(currentTrade.market));
            dispatch(GetUnexecutedOrders(currentTrade.market));
          } catch (ex) {
          } finally {
            setTimeout(() => {
              setDisabledCheckBoxOpen(false);
            }, 5000);
          }
        } else {
          try {
            setDisabledCheckBoxOpen(true);
            dispatch(GetUserStopOrdersList());
            dispatch(GetUnexecutedOrders());
          } catch (ex) {
          } finally {
            setTimeout(() => {
              setDisabledCheckBoxOpen(false);
            }, 5000);
          }
        }
      }
    }

    return () => (mounted = false);
  }, [currentTrade, isUserLogin, showThisMarketOpen]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (!isEmpty(currentTrade)) {
        if (!isEmpty(isUserLogin)) {
          if (showThisMarketTable) {
            try {
              setDisabledCheckBoxTable(true);
              dispatch(getUserMarketOrdersList(currentTrade.market));
            } catch (ex) {
            } finally {
              setTimeout(() => {
                setDisabledCheckBoxTable(false);
              }, 5000);
            }
          } else {
            try {
              setDisabledCheckBoxTable(true);
              dispatch(getUserMarketOrdersList());
            } catch (ex) {
            } finally {
              setTimeout(() => {
                setDisabledCheckBoxTable(false);
              }, 5000);
            }
          }
        }
      }
    }
    return () => (mounted = false);
  }, [currentTrade, isUserLogin, showThisMarketTable]);

  useEffect(() => {
    let timer;
    if (!isEmpty(isUserLogin) && updateStopList) {
      try {
        if (showThisMarketOpen) {
          dispatch(GetUserStopOrdersList(currentTrade.market));
          dispatch(GetUnexecutedOrders(currentTrade.market));
        } else {
          dispatch(GetUserStopOrdersList());
          dispatch(GetUnexecutedOrders());
        }
        if (showThisMarketTable) {
          dispatch(getUserMarketOrdersList(currentTrade.market));
        } else {
          dispatch(getUserMarketOrdersList());
        }
      } catch (ex) {
      } finally {
        timer = setTimeout(() => {
          setUpdateStopList(false);
          clearTimeout(timer);
        }, 5000);
      }
    }
    return () => clearTimeout(timer);
  }, [updateStopList]);

  function getNewAssets() {
    const assetUserBalance = coinUsersBalance(currentTrade.asset);
    const areaUserBalance = coinUsersBalance(currentTrade.trading_area);

    Promise.all([assetUserBalance, areaUserBalance]).then((values) => {
      setAssetBalance(
        values[0].data.asset.balance - values[0].data.asset.locked
      );
      let lockAmount = 0;
      if (values[1].data.asset.locked > 0) {
        lockAmount = values[1].data.asset.locked + 1;
      } else {
        lockAmount = values[1].data.asset.locked;
      }
      const IRT_AMOUNT = (values[1].data.asset.balance - lockAmount)?.toFixed(
        2
      );
      setAreaBalance(IRT_AMOUNT);
    });
  }

  //* -> Limit
  const handleBuyLimit = async (confirm) => {
    if (!orderPriceBuyLimit) return toast.error("قیمت مد نظر را وارد کنید");

    if (!orderAmountBuyLimit) return toast.error("تعداد مد نظر را وارد کنید");
    if (+orderAmountBuyLimit < +currentTrade.least_amount)
      return toast.error(
        `حداقل تعداد خرید ${currentTrade.least_amount} میباشد.`
      );
    if ((+orderPriceBuyLimit * +orderAmountBuyLimit)?.toFixed(2) > +areaBalance)
      return toast.error("موجودی شما کافی نمیباشد.");

    const EkhtelegeGheymat =
      (Number(orderPriceBuyLimit) - Number(lastOffer)) * 100;
    const _PERCNET = (EkhtelegeGheymat / Number(lastOffer)).toFixed(2);
    if (_PERCNET >= 10 || _PERCNET <= -10) {
      if (!confirm) {
        return acceptProccessTrade(_PERCNET, "handleBuyLimit");
      }
    }
    try {
      setDisableSuccessBtn(true);
      const { data } = await FetchMakeLimitOrder(
        JSON.stringify({
          market: currentTrade.market,
          orderType: "buy",
          orderAmount: orderAmountBuyLimit,
          orderPrice: orderPriceBuyLimit,
        })
      );
      if (data.error) {
        toast.error(data.error);
      }
      if (data.message) {
        setOrderAmountBuyLimit("");
        setOrderPriceBuyLimit("");
        setBuyLimitSlider(0);
        toast.success(data.message, { autoClose: 7000 });
        getNewAssets();
        dispatch(getUserMarketOrdersList());
        dispatch(GetUnexecutedOrders());
      }
    } catch (ex) {
    } finally {
      setDisableSuccessBtn(false);
    }
  };
  const handleSellLimit = async (confirm) => {
    if (!orderPriceSellLimit) return toast.error("قیمت مد نظر را وارد کنید");
    if (!orderAmountSellLimit)
      return toast.error(
        `تعداد ${currentTrade.asset_title_fa} برای فروش را وارد کنید`
      );
    if (+orderAmountSellLimit < +currentTrade.least_amount)
      return toast.error(
        `حداقل تعداد فروش ${currentTrade.least_amount} میباشد.`
      );
    if (+orderAmountSellLimit > +assetBalance)
      return toast.error(
        `موجودی ${currentTrade.asset_title_fa} شما کافی نمیباشد.`
      );
    const EkhtelegeGheymat =
      (Number(orderPriceSellLimit) - Number(lastOffer)) * 100;
    const _PERCNET = (EkhtelegeGheymat / Number(lastOffer)).toFixed(2);
    if (_PERCNET >= 10 || _PERCNET <= -10) {
      if (!confirm) {
        return acceptProccessTrade(_PERCNET, "handleSellLimit");
      }
    }
    try {
      setDisableDangerBtn(true);
      const { data } = await FetchMakeLimitOrder(
        JSON.stringify({
          market: currentTrade.market,
          orderType: "sell",
          orderAmount: orderAmountSellLimit,
          orderPrice: orderPriceSellLimit,
        })
      );
      if (data.error) {
        toast.error(data.error);
      }
      if (data.message) {
        setOrderAmountSellLimit("");
        setOrderPriceSellLimit("");
        setSellLimitSlider(0);
        toast.success(data.message, { autoClose: 7000 });
        getNewAssets();
        dispatch(getUserMarketOrdersList());
        dispatch(GetUnexecutedOrders());
      }
    } catch (ex) {
    } finally {
      setDisableDangerBtn(false);
    }
  };

  //* --> Functions
  //* -> Stop-Limit
  const handleBuyStopLimit = async () => {
    if (!orderBuyStopPrice) return toast.error("قیمت استاپ را وارد کنید.");
    if (!orderPriceBuyStopLimit)
      return toast.error(
        `قیمت لیمیت ${currentTrade.area_title_fa} را وارد کنید`
      );
    if (!orderAmountBuyStopLimit)
      return toast.error(
        `تعداد ${currentTrade.asset_title_fa} برای خرید را وارد کنید`
      );
    if (+orderAmountBuyStopLimit < +currentTrade.least_amount)
      return toast.error(
        `حداقل تعداد خرید ${currentTrade.least_amount} میباشد.`
      );
    if (
      (+orderPriceBuyStopLimit * +orderAmountBuyStopLimit)?.toFixed(2) >
      +areaBalance
    )
      return toast.error(
        `موجودی ${currentTrade.area_title_fa} شما کافی نمیباشد.`
      );
    try {
      setDisableSuccessBtn(true);
      const { data } = await FetchMakeStopLimitOrder(
        JSON.stringify({
          market: currentTrade.market,
          orderType: "buy",
          orderAmount: orderAmountBuyStopLimit,
          orderPrice: orderPriceBuyStopLimit,
          stopPrice: orderBuyStopPrice,
        })
      );
      if (data.error) {
        toast.error(data.error);
      }
      if (data.message) {
        setOrderAmountBuyStopLimit("");
        setOrderPriceBuyStopLimit("");
        setOrderBuyStopPrice("");
        setBuyStopLimitSlider(0);
        toast.success(data.message, { autoClose: 7000 });
        getNewAssets();
        if (showThisMarketOpen) {
          dispatch(GetUserStopOrdersList(currentTrade.market));
        } else {
          dispatch(GetUserStopOrdersList());
        }
        if (showThisMarketTable) {
          dispatch(GetUnexecutedOrders(currentTrade.market));
        } else {
          dispatch(GetUnexecutedOrders());
        }
      }
    } catch (ex) {
    } finally {
      setDisableSuccessBtn(false);
    }
  };
  const handleSellStopLimit = async () => {
    if (!orderSellStopPrice) return toast.error("قیمت استاپ را وارد کنید.");
    if (!orderPriceSellStopLimit)
      return toast.error(
        `قیمت لیمیت ${currentTrade.area_title_fa} را وارد کنید`
      );
    if (!orderAmountSellStopLimit)
      return toast.error(
        `تعداد ${currentTrade.asset_title_fa} برای فروش را وارد کنید`
      );
    if (+orderAmountSellStopLimit < +currentTrade.least_amount)
      return toast.error(
        `حداقل تعداد فروش ${currentTrade.least_amount} میباشد.`
      );
    if (+orderAmountSellStopLimit > +assetBalance)
      return toast.error(
        `موجودی ${currentTrade.asset_title_fa} شما کافی نمیباشد.`
      );
    try {
      setDisableDangerBtn(true);
      const { data } = await FetchMakeStopLimitOrder(
        JSON.stringify({
          market: currentTrade.market,
          orderType: "sell",
          orderAmount: orderAmountSellStopLimit,
          orderPrice: orderPriceSellStopLimit,
          stopPrice: orderSellStopPrice,
        })
      );
      if (data.error) {
        toast.error(data.error);
      }
      if (data.message) {
        setOrderAmountSellStopLimit("");
        setOrderPriceSellStopLimit("");
        setOrderSellStopPrice("");
        setSellStopLimitSlider(0);
        toast.success(data.message, { autoClose: 7000 });
        getNewAssets();
        if (showThisMarketOpen) {
          dispatch(GetUserStopOrdersList(currentTrade.market));
        } else {
          dispatch(GetUserStopOrdersList());
        }
        if (showThisMarketTable) {
          dispatch(getUserMarketOrdersList(currentTrade.market));
        } else {
          dispatch(getUserMarketOrdersList());
        }
      }
    } catch (ex) {
    } finally {
      setDisableDangerBtn(false);
    }
  };

  //* Functios
  //* Market Orders
  const handleBuyMarket = async () => {
    if (!orderMarketPrice)
      return toast.error(`قیمت  ${currentTrade.area_title_fa} را وارد کنید`);
    if (+orderMarketPrice > +areaBalance)
      return toast.error(
        `موجودی ${currentTrade.area_title_fa} شما کافی نمیباشد.`
      );
    try {
      setDisableSuccessBtn(true);
      const { data } = await FetchMakeMarketOrder(
        JSON.stringify({
          market: currentTrade.market,
          orderType: "buy",
          orderAmount: orderMarketPrice,
        })
      );
      if (data.error) {
        toast.error(data.error);
      }
      if (data.message) {
        setBuyMarketSlider(0);
        setOrderMarketPrice("");
        toast.success(data.message, { autoClose: 7000 });
        getNewAssets();
        if (showThisMarketTable) {
          dispatch(getUserMarketOrdersList(currentTrade.market));
        } else {
          dispatch(getUserMarketOrdersList());
        }
      }
    } catch (ex) {
    } finally {
      setDisableSuccessBtn(false);
    }
  };
  const handleSellMarket = async () => {
    if (!orderMarketAmount)
      return toast.error(`تعداد ${currentTrade.asset_title_fa} را وارد کنید`);
    if (+orderMarketAmount < +currentTrade.least_amount)
      return toast.error(
        `حداقل تعداد فروش ${currentTrade.least_amount} میباشد.`
      );
    if (+orderMarketAmount > +assetBalance)
      return toast.error(
        `موجودی ${currentTrade.asset_title_fa} شما کافی نمیباشد.`
      );
    try {
      setDisableDangerBtn(true);
      const { data } = await FetchMakeMarketOrder(
        JSON.stringify({
          market: currentTrade.market,
          orderType: "sell",
          orderAmount: orderMarketAmount,
        })
      );
      if (data.error) {
        toast.error(data.error);
      }
      if (data.message) {
        setOrderMarketAmount("");
        setSellMarketSlider(0);
        toast.success(data.message, { autoClose: 7000 });
        getNewAssets();
        if (showThisMarketTable) {
          dispatch(getUserMarketOrdersList(currentTrade.market));
        } else {
          dispatch(getUserMarketOrdersList());
        }
      }
    } catch (ex) {
    } finally {
      setDisableDangerBtn(false);
    }
  };

  //* --> Stop-Market Function's
  const handleBuyStopMarket = async () => {
    if (!orderBuyPriceStopMarket)
      return toast.error(`قیمت  ${currentTrade.area_title_fa} را وارد کنید`);
    if (!orderBuyAmountStopMarket)
      return toast.error(`مقدار  ${currentTrade.area_title_fa} را وارد کنید`);
    if (+orderBuyAmountStopMarket > +areaBalance)
      return toast.error(
        `موجودی ${currentTrade.area_title_fa} شما کافی نمیباشد.`
      );
    try {
      setDisableSuccessBtn(true);
      const { data } = await FetchMakeStopMarketOrder(
        JSON.stringify({
          market: currentTrade.market,
          orderType: "buy",
          orderAmount: orderBuyAmountStopMarket,
          stopPrice: orderBuyPriceStopMarket,
        })
      );
      if (data.error) {
        toast.error(data.error);
      }
      if (data.message) {
        setOrderBuyPriceStopMarket("");
        setOrderBuyAmountStopMarket("");
        toast.success(data.message, { autoClose: 7000 });
        getNewAssets();
        if (showThisMarketOpen) {
          dispatch(GetUserStopOrdersList(currentTrade.market));
        } else {
          dispatch(GetUserStopOrdersList());
        }
        if (showThisMarketTable) {
          dispatch(getUserMarketOrdersList(currentTrade.market));
        } else {
          dispatch(getUserMarketOrdersList());
        }
      }
    } catch (ex) {
    } finally {
      setDisableSuccessBtn(false);
    }
  };
  const handleSellStopMarket = async () => {
    if (!orderSellPriceStopMarket)
      return toast.error(`قیمت  ${currentTrade.area_title_fa} را وارد کنید`);
    if (!orderSellAmountStopMarket)
      return toast.error(`مقدار  ${currentTrade.asset_title_fa} را وارد کنید`);
    if (+orderSellAmountStopMarket > +assetBalance)
      return toast.error(
        `موجودی ${currentTrade.asset_title_fa} شما کافی نمیباشد.`
      );
    try {
      setDisableDangerBtn(true);
      const { data } = await FetchMakeStopMarketOrder(
        JSON.stringify({
          market: currentTrade.market,
          orderType: "sell",
          orderAmount: orderSellAmountStopMarket,
          stopPrice: orderSellPriceStopMarket,
        })
      );
      if (data.error) {
        toast.error(data.error);
      }
      if (data.message) {
        setOrderSellAmountStopMarket("");
        setOrderSellPriceStopMarket("");
        toast.success(data.message, { autoClose: 7000 });
        getNewAssets();
        if (showThisMarketOpen) {
          dispatch(GetUserStopOrdersList(currentTrade.market));
        } else {
          dispatch(GetUserStopOrdersList());
        }
        if (showThisMarketTable) {
          dispatch(getUserMarketOrdersList(currentTrade.market));
        } else {
          dispatch(getUserMarketOrdersList());
        }
      }
    } catch (ex) {
    } finally {
      setDisableDangerBtn(false);
    }
  };

  //# Handle Accept Porcces To Continue Trade, Trade With 10Percent Changes
  const acceptProccessTrade = (percent, callback) => {
    setCallBackConfirm(callback);
    setModalMessage(`قیمت مد نظر با قیمت بازار ${percent} درصد مغایرت دارد.`);
    setShowConfirmModal(true);
  };

  //* --> Other Function's
  const handleReplaceTradesAmount = (num, current) => {
    if (current) {
      setOrderPriceBuyLimit(num);
      setOrderPriceSellLimit(num);
      setOrderPriceBuyStopLimit(num);
      setOrderPriceSellStopLimit(num);
      setOrderBuyStopPrice(num);
      setOrderSellStopPrice(num);
      setOrderBuyPriceStopMarket(num);
      setOrderSellPriceStopMarket(num);
      return;
    }
    setOrderBuyPriceStopMarket(num.price);
    setOrderSellPriceStopMarket(num.price);
    setOrderPriceBuyLimit(num.price);
    setOrderPriceSellLimit(num.price);
    setOrderPriceBuyStopLimit(num.price);
    setOrderPriceSellStopLimit(num.price);
    setOrderBuyStopPrice(num.price);
    setOrderSellStopPrice(num.price);
  };

  function clearSliders() {
    setBuyLimitSlider(0);
    setSellLimitSlider(0);
    setBuyStopLimitSlider(0);
    setSellStopLimitSlider(0);
    setBuyMarketSlider(0);
    setSellMarketSlider(0);
    setBuyStopMarketSlider(0);
    setSellStopMarketSlider(0);
  }
  function clearStates() {
    setOrderPriceBuyLimit("");
    setOrderAmountBuyLimit("");
    setOrderPriceSellLimit("");
    setOrderAmountSellLimit("");
    setOrderPriceBuyStopLimit("");
    setOrderPriceSellStopLimit("");
    setOrderAmountBuyStopLimit("");
    setOrderAmountSellStopLimit("");
    setOrderBuyStopPrice("");
    setOrderSellStopPrice("");
    setOrderMarketAmount("");
    setOrderMarketPrice("");
    setOrderBuyPriceStopMarket("");
    setOrderSellPriceStopMarket("");
    setOrderBuyAmountStopMarket("");
    setOrderSellAmountStopMarket("");
  }

  const handleConfirmProcessToMakeOrder = () => {
    switch (callBackConfirm) {
      case "handleBuyLimit":
        handleBuyLimit(true);
        break;
      case "handleBuyMarket":
        handleBuyMarket(true);
        break;
      case "handleSellLimit":
        handleSellLimit(true);
        break;
      case "handleSellMarket":
        handleSellMarket(true);
        break;
      default:
        break;
    }
    setShowConfirmModal(false);
  };
  return (
    <tradesCTX.Provider
      value={{
        market,
        setMarket,
        percentChanges,
        lastTrades,
        setLastTrades,
        buyOffers,
        setBuyOffers,
        sellOffers,
        setSellOffers,
        lastOffer,
        setLastOffer,
        sumAllOffers,
        setSumAllOffers,
        handleReplaceTradesAmount,
        merge,
        setMerge,
        mergeList,
        setMergeList,
        refereshList,
        setRefreshList,
        marketWSInfo,
        setMarketWSInfo,
        updateStopList,
        setUpdateStopList,
        getNewAssets,
        currentTrade,
        setCurrentTrade,
        currentTradeUpdate,
        setCurrentTradeUpdate,
        asideStatus,
        setAsideStatus,
        marketType,
        setMarketType,
        menuStatusMarketType,
        setMenuStatusMarketType,
        typeOfTrade,
        setTypeOfTrade,
        buyLimitSlider,
        setBuyLimitSlider,
        sellLimitSlider,
        setSellLimitSlider,
        buyMarketSlider,
        setBuyMarketSlider,
        sellMarketSlider,
        setSellMarketSlider,
        buyStopLimitSlider,
        setBuyStopLimitSlider,
        sellStopLimitSlider,
        setSellStopLimitSlider,
        buyStopMarketSlider,
        setBuyStopMarketSlider,
        sellStopMarketSlider,
        setSellStopMarketSlider,
        sortListBy,
        setSortListBy,
        showSortListMenu,
        setShowSortListMenu,
        //* --> State's For Limit-Orders
        orderPriceBuyLimit,
        setOrderPriceBuyLimit,
        orderAmountBuyLimit,
        setOrderAmountBuyLimit,
        orderPriceSellLimit,
        setOrderPriceSellLimit,
        orderAmountSellLimit,
        setOrderAmountSellLimit,
        assetBalance,
        setAssetBalance,
        handleBuyLimit,
        handleSellLimit,
        //* --> State's For Stop-Limit-Orders
        orderPriceBuyStopLimit,
        setOrderPriceBuyStopLimit,
        orderAmountBuyStopLimit,
        setOrderAmountBuyStopLimit,
        orderPriceSellStopLimit,
        setOrderPriceSellStopLimit,
        orderAmountSellStopLimit,
        setOrderAmountSellStopLimit,
        orderBuyStopPrice,
        setOrderBuyStopPrice,
        orderSellStopPrice,
        setOrderSellStopPrice,
        handleBuyStopLimit,
        handleSellStopLimit,
        //* --> Buttons && User Balances
        disableSuccessBtn,
        setDisableSuccessBtn,
        disableDangerBtn,
        setDisableDangerBtn,
        areaBalance,
        setAreaBalance,
        //* --> CheckBox's
        showThisMarketTable,
        setShowThisMarketTable,
        showThisMarketOpen,
        setShowThisMarketOpen,
        disabledCheckBoxTable,
        setDisabledCheckBoxTable,
        disabledCheckBoxOpen,
        setDisabledCheckBoxOpen,
        //* --> Market State's
        orderMarketAmount,
        setOrderMarketAmount,
        orderMarketPrice,
        setOrderMarketPrice,
        handleSellMarket,
        handleBuyMarket,
        //* --> Stop Market State's & Functions
        orderBuyAmountStopMarket,
        setOrderBuyAmountStopMarket,
        orderBuyPriceStopMarket,
        setOrderBuyPriceStopMarket,
        orderSellAmountStopMarket,
        setOrderSellAmountStopMarket,
        orderSellPriceStopMarket,
        setOrderSellPriceStopMarket,
        handleBuyStopMarket,
        handleSellStopMarket,
      }}
    >
      <ConfirmWithMessage
        open={showConfirmModal}
        setOpen={setShowConfirmModal}
        message={modalMessage}
        handleConfirm={handleConfirmProcessToMakeOrder}
      />
      {hasLoading && <LoadingPage />}
      {!LEGALL_MARKET && (
        <div className="illegal_market">
          <div className="illegal_market_box">
            <div className="illegal_market_logo">
              <img
                src={appLogo}
                alt="ArzFi-Logo"
                onClick={() => history.push("/")}
              />
            </div>
            <p>
              به طور موقت بازار ارز {currentTrade["market"]} غیر فعال می باشد.
            </p>
            <p>لطفا به صفحه خرید سریع مراجعه نمایید.</p>
            <div className="illegal_button_contianer">
              <Link to={`/buy/${currentTrade["asset"]}?type=IRT`}>
                <button>خرید سریع {currentTrade["asset_title_fa"]}</button>
              </Link>
            </div>
          </div>
        </div>
      )}
      {children}
    </tradesCTX.Provider>
  );
}
export default withRouter(OrdersContext);
