import { Collapse, Grid } from "@mui/material";
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import { memo } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import LikeIcon from "../../../styles/icon/Like.svg";
import LikedIcon from "../../../styles/icon/Liked.svg";
import _ from "lodash";
//# STYLES
import styles from "./HomeTable.module.scss";
import ProgressLoading from "../../../components/Loadings/ProgressLoading";
import { Link } from "react-router-dom";
import { addCommaWithRialPrice } from "../../../utils/addComma";
import { AddSocketPrice } from "../../../actions/appSocketPriceAction";
import { addFavoriteCoins } from "../../../actions/favoriteCoins";
import ImagePreview from "../../../utils/ImagePreview";
import { changeMarketRoute } from "../../../actions/RouteStateActions";
import STABLE_COINS from "../../../utils/stable_coins";

//# Filter Buttons
const F_BTN = ["رمز ارزهای برتر", "پر ضررها", "پر سودها", "سفارش سریع"];
const NoUSDTOrder = ["USDT"];
//# Table Header
function HomeTable() {
  const dispatch = useDispatch();
  //# Redux States
  const MarketFromAPI = useSelector((state) => state.market.data);
  const socketReducer = useSelector((state) => state.stateReducer);
  const appPrice = useSelector((state) => state.appPrice);
  
  const favUserCoins = useSelector((state) => state.favCoins);

  //# Component State
  const [filterID, setFilterID] = useState("0");
  const [marketsShow, setMarketsShow] = useState([]);
  const [collapseInfo, setCollapseInfo] = useState({ id: null, target: "" });
  //# Collapse.ID => Name Of Coin, Target =>  IRT | USDT

  //# Hooks
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      switch (filterID) {
        case "1":
          setMarketsShow(
            _.take(_.sortBy(MarketFromAPI, "percent_change_24h", "asc"), 10)
          );
          break;
        case "2":
          setMarketsShow(
            _.take(
              _.sortBy(MarketFromAPI, "percent_change_24h", "desc").reverse(),
              10
            )
          );
          break;
        default:
          setMarketsShow(_.take(_.sortBy(MarketFromAPI, "cmcRank", "asc"), 10));
          break;
      }
    }
    return () => (mounted = false);
  }, [filterID, MarketFromAPI]);


  //Functsions
  const handleChangeFilter = (idx) => {
    return setFilterID(String(idx));
  };
  //# Show Amount Of Current Market
  const handleShowPrice = (symbol, price) => {
    if (!_.isEmpty(socketReducer)) {
      if (appPrice === "IRT") {
        if (STABLE_COINS.indexOf(symbol) === -1) {
          if (socketReducer[symbol + "IRT"]) {
            return addCommaWithRialPrice(socketReducer[symbol + "IRT"].last);
          } else {
            return "...";
          }
        } else {
          return addCommaWithRialPrice(price);
        }
      } else {
        if (socketReducer[symbol + "USDT"]) {
          return socketReducer[symbol + "USDT"].last;
        } else {
          return "...";
        }
      }
    } else {
      return "...";
    }
  };

  const handleToggleFavorite = (event, name) => {
    event.preventDefault();
    dispatch(addFavoriteCoins(name));
  };

  const handleOpenCollapse = (e, symbol) => {
    setCollapseInfo({ id: symbol, target: e.target.name });
  };

  const handleDispatchFastOrderType = () => {
    return dispatch(changeMarketRoute("order"));
  };

  return (
    <Stack className={styles.container}>
      <Stack className={styles.filter_container}>
        {F_BTN.map((ctx, idx) => {
          return (
            <button
              key={idx}
              data-active={Number(filterID) === idx}
              data-index={idx}
              onClick={() => handleChangeFilter(idx)}
            >
              {ctx}
            </button>
          );
        })}
      </Stack>
      <Stack>
        <Grid container className={styles.head}>
          {filterID !== "3" ? (
            <>
              <Grid item xs={1.5}></Grid>
              <Grid item xs={3.5}>
                تغییرات
              </Grid>
            </>
          ) : (
            <Grid item xs={5}>
              عملیات
            </Grid>
          )}
          <Grid item xs={3.5}>
            قیمت ({appPrice === "IRT" ? "تومان" : "دلار"})
          </Grid>
          <Grid item xs={3.5}>
            ارز
          </Grid>
        </Grid>
        <Stack className={styles.body}>
          {!_.isEmpty(MarketFromAPI) ? (
            filterID !== "3" ? (
              marketsShow.map((ctx, idx) => {
                return (
                  <Link to={`/market/trade?market=${ctx.symbol}IRT`} key={idx}>
                    <Grid container className={styles.item}>
                      <Grid item xs={1.5} textAlign="right" mt={1}>
                        <span
                          onClick={(e) => handleToggleFavorite(e, ctx.name)}
                        >
                          <img
                            src={
                              favUserCoins.includes(ctx.name)
                                ? LikedIcon
                                : LikeIcon
                            }
                            alt={`Liked-${ctx.name}`}
                            width={17}
                            height={17}
                          />
                        </span>
                      </Grid>
                      <Grid item xs={3.5}>
                        <Stack justifyContent="center" alignItems="center">
                          <Stack
                            className={styles.percent_box}
                            data-positive={ctx.percent_change_24h >= 0}
                          >
                            <span className="eng-number-font">
                              {ctx.percent_change_24h}%
                            </span>
                          </Stack>
                        </Stack>
                      </Grid>
                      <Grid item xs={3.5}>
                        <Stack className={`eng-number-font ${styles.value}`}>
                          {handleShowPrice(ctx.symbol, ctx.arzfi_price)}
                        </Stack>
                      </Grid>
                      <Grid item xs={3.5}>
                        <Stack className={styles.symbol}>
                          <span>
                            <ImagePreview
                              image={ctx.symbol}
                              width={30}
                              height={30}
                            />
                          </span>

                          <strong>{ctx.symbol}</strong>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Link>
                );
              })
            ) : (
              <Stack>
                {marketsShow.map((ctx, idx) => {
                  return (
                    <Stack
                      key={idx}
                      data-active={collapseInfo.id === ctx.symbol}
                      className={styles.coin_container}
                    >
                      <Grid container className={styles.item}>
                        <Grid item xs={5}>
                          <Stack className={styles.fast_order_buttons}>
                            <button
                              data-type="sell"
                              name="sell"
                              onClick={(e) => handleOpenCollapse(e, ctx.symbol)}
                            >
                              فروش
                            </button>
                            <button
                              data-type="buy"
                              name="buy"
                              onClick={(e) => handleOpenCollapse(e, ctx.symbol)}
                            >
                              خرید
                            </button>
                          </Stack>
                        </Grid>
                        <Grid item xs={3.5}>
                          <Stack className={`eng-number-font ${styles.value}`}>
                            {handleShowPrice(ctx.symbol, ctx.arzfi_price)}
                          </Stack>
                        </Grid>
                        <Grid item xs={3.5}>
                          <Stack className={styles.symbol}>
                            <span>
                              <ImagePreview
                                image={ctx.symbol}
                                width={30}
                                height={30}
                              />
                            </span>

                            <strong>{ctx.symbol}</strong>
                          </Stack>
                        </Grid>
                      </Grid>
                      <Collapse in={collapseInfo.id === ctx.symbol}>
                        <Stack className={styles.link_fast_order}>
                          {collapseInfo.target === "buy" ? (
                            <>
                              {!NoUSDTOrder.includes(ctx.symbol) && (
                                <Link to={`/buy/${ctx.symbol}?type=USDT`}>
                                  <button data-type="buy">خرید با تتر</button>
                                </Link>
                              )}
                              <Link to={`/buy/${ctx.symbol}?type=IRT`}>
                                <button data-type="buy">خرید با تومان</button>
                              </Link>
                            </>
                          ) : (
                            <>
                              {!NoUSDTOrder.includes(ctx.symbol) && (
                                <Link to={`/sell/${ctx.symbol}?type=USDT`}>
                                  <button data-type="sell">فروش به تتر</button>
                                </Link>
                              )}
                              <Link to={`/sell/${ctx.symbol}?type=IRT`}>
                                <button data-type="sell">فروش به تومان</button>
                              </Link>
                            </>
                          )}
                        </Stack>
                      </Collapse>
                    </Stack>
                  );
                })}
                <Stack
                  className={styles.more_fast_orders}
                  onClick={handleDispatchFastOrderType}
                >
                  <Link to="/market">مشاهده همه ارزها</Link>
                </Stack>
              </Stack>
            )
          ) : (
            <Stack height="150px" justifyContent="center">
              <ProgressLoading />
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}
export default memo(HomeTable);
