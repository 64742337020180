import { useLayoutEffect } from "react";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hiddenAppMenu, showAppMenu } from "../../../actions/visibiltyOfMenu";
import OrderCoins from "../../../pageComponents/MarketLists/OrderCoins";
import appIcons from "../../../utils/appIcons";
import MuiTextField from "../../MuiTextField";

//# --> STYLES
import styles from "./SwapCoins.module.scss";

function SwapCoins(props) {
  const { showList, setShowList } = props;
  const dispatch = useDispatch();
  //# State's
  const [search, setSearch] = useState("");
  const [isPWA, setIsPWA] = useState(false);
  const socketReducer = useSelector((state) => state.stateReducer);
  //# Handle Toggle The Bottom Menu Of Application ##
  useEffect(() => {
    if (showList) dispatch(hiddenAppMenu());
    return () => {
      dispatch(showAppMenu());
    };
  }, [dispatch, showList]);
  useLayoutEffect(() => {
    if (
      window !== undefined &&
      !window.matchMedia("(display-mode: standalone)").matches
    ) {
      setIsPWA(false);
    } else {
      setIsPWA(true);
    }
  }, []);
  const handleCloseList = () => {
    setShowList(false);
  };

  return (
    <div className={styles.container} data-show={showList} data-ispwa={isPWA}>
      <div className={styles.head}>
        <div className={styles.close_box}>
          <span onClick={handleCloseList}>{appIcons("ArrowBackIcon")}</span>
        </div>
        <div className={styles.search_box}>
          <MuiTextField
            placeholder="جست و جو..."
            size="small"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            fullWidth
          />
        </div>
      </div>
      <div className={styles.list}>
        <OrderCoins
          search={search}
          handleButtonsClicked={handleCloseList}
          marketWSInfo={socketReducer}
        />
      </div>
    </div>
  );
}
export default memo(SwapCoins);
