import React from "react";
import { Box, Button, Container, Stack } from "@mui/material";

import PrimaryHeader from "../components/Headers/PrimaryHeader";
import styles from "../pageComponents/ReferralPage/ReferralPage.module.scss";
import { useReducer } from "react";
import {
  getReferralDetails,
  initialStates,
  referralReducer,
} from "../pageComponents/ReferralPage/state";
import { useMemo } from "react";
import OverviewReferral from "../pageComponents/ReferralPage/OverviewReferral";
import CodeReferral from "../pageComponents/ReferralPage/CodeReferral";
import FriendsReferral from "../pageComponents/ReferralPage/FriendsReferral";
import { useLayoutEffect } from "react";
import LoadingModal from "../components/Modals/LoadingModal";
import useSocketConnection from "../hooks/useSocketConnection";

const ButtonTabs = [
  { id: "overview", name: "مرور کلی" },
  { id: "codes", name: "کدهای دعوت" },
  { id: "friends", name: "لیست دوستان" },
];
export const profitCalc = (per, val, isF) => {
  const userPercent = per * 0.01;
  if (!isF) {
    return Math.round(val * userPercent);
  }
};
export default function Referral() {

  const [state, dispatch] = useReducer(referralReducer, initialStates);

  useLayoutEffect(() => {
    (async () => {
      const controller = new AbortController();
      const { error, data } = await getReferralDetails(controller.signal);
      if (error) return dispatch({ type: "INIT_FAILED" });
      return dispatch({ type: "INIT_SUCCESS", payload: data });
    })();
  }, []);

  const ViewComponent = useMemo(() => {
    const CMPS = {
      overview: <OverviewReferral state={state} />,
      codes: <CodeReferral state={state} dispatch={dispatch} />,
      friends: <FriendsReferral state={state} dispatch={dispatch} />,
    }[state["view"]];
    return CMPS ?? "";
  }, [state]);

  return (
    <Container sx={{ marginBottom: 2 }}>
      <PrimaryHeader title="معرفی دوستان" fixButton />
      <Box component="div" sx={{ direction: "rtl" }}>
        <Stack direction="row" borderBottom="1px solid var(--border-color)">
          {ButtonTabs.map((ctx) => {
            return (
              <Button
                variant="text"
                color="inherit"
                key={ctx["id"]}
                data-selected={ctx["id"] === state["view"]}
                className={styles.buttonItems}
                onClick={() => dispatch({ type: "VIEW", payload: ctx["id"] })}
                size="small"
              >
                {ctx["name"]}
              </Button>
            );
          })}
        </Stack>
      </Box>
      {ViewComponent}
      <LoadingModal visible={state["isLoading"]} />
    </Container>
  );
}
