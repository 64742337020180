import { useEffect, useState } from "react";
import { CopyThisText } from "../../../helpers/CopyToClipboard";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { addUsersNetworkWallet } from "../../../services/userServices";
import { updateUsersDeposits } from "../../../services/userServices";

//Components
import NotificationDeposit from "../../../helpers/errors_notifications/NotificationDeposit";
import QRCodeMaker from "../../../utils/QRCodeMaker";
//Styles and Icons
import styles from "./DepositCoin.module.scss";
import RectAngel from "../../../styles/icon/rectangel2.svg";
import TimerIcon from "../../../styles/icon/timer.svg";
import PolygonBlue from "../../../styles/icon/polygon_blue.svg";
import PolygonGreen from "../../../styles/icon/polygon_green.svg";
import WaningIcon from "../../../styles/icon/warning.svg";
import ArrowIcon from "../../../styles/icon/arrow_wallet.svg";
import CopyIcon from "../../../styles/icon/copyicon.svg";
import { clearCoinNetworks, coinNetworks } from "../../../actions/coinNetworks";
import { userCoinBalance } from "../../../actions/user";
import { showBetterCoinBalance } from "../../../utils/showBetterPrice";
import { isEmpty } from "lodash";
import { errorMessage, successMessage } from "../../../utils/messages";
import serviceConfig from "../../../services/config.json";

export default function DepositCoin() {
  const userBalance = useSelector((state) => state.fullUserInfo.coinBalance);
  const coinNetwork = useSelector((state) => state.network);
  const dispatch = useDispatch();
  const { name } = useParams();
  const [openNetworkList, setOpenNetworkList] = useState(true);
  const [depositNotification, setDepositNotification] = useState(true);
  const [disableUpdateBtn, setDisableUpdateBtn] = useState(false);
  const [depositDetails, setDepositDetails] = useState({
    chain: "",
    currentNetwork: "",
  });
  const [currentUserWallet, setCurrentUserWallet] = useState({
    address: "USE_COPY",
    platform: "",
    disableButton: false,
  });
  const [chains, setChains] = useState([]);
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      const getInfo = async () => {
        await dispatch(userCoinBalance(name));
        await dispatch(coinNetworks(name));
      };
      getInfo();
    }
    return () => {
      mounted = false;
      dispatch(clearCoinNetworks());
    };
  }, []);

  ////
  ///NETWORK
  ///

  useEffect(() => {
    if (!isEmpty(depositDetails.currentNetwork))
      setCurrentUserWallet({
        ...currentUserWallet,
        address:
          coinNetwork?.wallets[depositDetails?.currentNetwork[0]?.chain]
            ?.address ?? false,
        platform:
          coinNetwork?.wallets[depositDetails?.currentNetwork[0]?.chain]
            ?.platform ?? false,
      });
  }, [depositDetails.currentNetwork]);

  useEffect(() => {
    const current = coinNetwork?.data?.filter((f) => f.can_deposit === true);
    setDepositDetails({
      ...depositDetails,
      chain: !isEmpty(current) ? current?.shift().chain : "LOCKED",
    });
    let Cns = [];
    coinNetwork?.data?.map((data) => {
      return Cns.push(data.chain);
    });
    setChains(Cns);
  }, [coinNetwork]);

  useEffect(() => {
    const currentVal = coinNetwork?.data?.filter(
      (coin) => coin.chain === depositDetails.chain
    );
    setDepositDetails({
      ...depositDetails,
      currentNetwork: { ...currentVal },
    });
  }, [depositDetails.chain]);

  const handleAddNewWallet = async () => {
    setCurrentUserWallet({ ...currentUserWallet, disableButton: true });
    const data = await addUsersNetworkWallet(
      name,
      depositDetails?.currentNetwork[0]?.chain
    );
    successMessage(data.data.message);
    if (data.data.address) {
      dispatch(coinNetworks(name));
      setCurrentUserWallet({
        ...currentUserWallet,
        address: data.data.address,
        platform: depositDetails?.currentNetwork[0]?.chain,
        disableButton: false,
      });
    }
  };
  const getUpdatePrice = async (item) => {
    try {
      setDisableUpdateBtn(true);
      const { data } = await updateUsersDeposits(item);
      successMessage(data.message);
    } catch (ex) {
      errorMessage("مشکلی در بروزرسانی رخ داد");
    } finally {
      const time = setTimeout(() => {
        setDisableUpdateBtn(false);
      }, 3000);
      return () => clearTimeout(time);
    }
  };
  const chainAsset =
    name === depositDetails.chain ? name : name + "-" + depositDetails.chain;
  return (
    <>
      {depositNotification && (
        <NotificationDeposit
          setDepositNotification={setDepositNotification}
          networks={chains}
        />
      )}
      <div className={`bg-secondary ${styles.container}`}>
        <div className={styles.container_responsive}>
          <div className={styles.header_of_deposit}>
            <div className={styles.coin_picture}>
              <img
                src={`${serviceConfig.image_url}/${userBalance?.asset?.symbol}.png`}
                alt={userBalance?.asset?.symbol}
                width={65}
                height={65}
              />
            </div>
            <div className={styles.title_of_page}>
              <img src={RectAngel} alt="" />
              <p>واریز</p>
            </div>
            <div className={`font_color_white ${styles.coin_name}`}>
              {userBalance?.asset?.name} ({userBalance?.asset?.symbol})
            </div>

            <button
              className={styles.refresh_button}
              onClick={() => getUpdatePrice(userBalance?.asset?.symbol)}
              disabled={disableUpdateBtn}
            >
              <div className={styles.refresh_icon}>
                <img src={TimerIcon} alt="" width={14} height={14} />
              </div>
              <div className={styles.refresh_text}>بروزرسانی</div>
            </button>
            <div className={styles.line_header_withdraw}></div>
          </div>
          <div
            className={`font_color_white  changeBackgroundColorToHardBlack ${styles.main_deposit}`}
          >
            <div
              className={`changeBackgroundColorToGreen font_color_white ${styles.coin_persian_name}`}
            >
              {userBalance?.asset?.faName}
            </div>
            <div className={styles.title_of_total_coin}>
              <img src={PolygonGreen} alt="" width={8} height={8} />
              <div className="font_color_white">: موجودی کل</div>
            </div>
            <div className={`${styles.total_coin}`}>
              <span>{userBalance?.asset?.symbol}</span>
              <span className="eng-number-font">
                {showBetterCoinBalance(userBalance?.asset?.balance)}
              </span>
            </div>
            <div className={styles.title_of_coin_locked}>
              <img src={PolygonBlue} alt="" width={8} height={8} />
              <div className="font_color_white">: قفل شده</div>
            </div>
            <div className={styles.total_coin_locked}>
              <span>{userBalance?.asset?.symbol}</span>
              <span className="eng-number-font">
                {showBetterCoinBalance(userBalance?.asset?.locked)}
              </span>
            </div>
            <div className={styles.title_removable_coin}>
              <img src={PolygonBlue} alt="" width={8} height={8} />
              <div className="font_color_white">: قابل برداشت</div>
            </div>
            <div className={styles.total_removable_coin}>
              <span>{userBalance?.asset?.symbol}</span>
              <span className="eng-number-font">
                {userBalance?.asset?.balance &&
                  userBalance?.asset?.balance - userBalance?.asset?.locked}
              </span>
            </div>
          </div>
          <div className={styles.check_network_container}>
            <div className={styles.checkbox_net_place}>
              <label htmlFor="checkbox-transfer-external">
                <input
                  type="checkbox"
                  className={`${styles.checkbox_input_current} checkbox-in-withdraw-external`}
                  id="checkbox-transfer-external"
                  defaultChecked
                  // onChange={(e) => setOpenNetworkList(e.target.checked)}
                />
                <input
                  type="text"
                  disabled
                  className={`changeBackgroundColorToHardBlack font_color_white ${styles.value_of_network_input}`}
                  placeholder="نوع شبکه"
                />
                <div className={`${styles.arrow_place_checkbox} `}>
                  <img
                    src={ArrowIcon}
                    alt=""
                    width={15}
                    height={15}
                    className="convertPicToGreen"
                  />
                </div>
              </label>
            </div>
            <div
              className={`changeBackgroundColorToHardBlack ${
                styles.list_of_networks
              } ${openNetworkList ? styles.show_list_of_network : ""}`}
            >
              <ul>
                {!isEmpty(coinNetwork) &&
                  coinNetwork.data.map((cn) => (
                    <li className={styles.list_network} key={cn.chain}>
                      <div className={` ${styles.label_network}`}>
                        <label>
                          <input
                            type="radio"
                            style={{ display: "none" }}
                            name="networkchoice"
                            value="ER0C20"
                            className={`RadioNetworkCheckDarkMode ${styles.RadioNetworkCheck}`}
                            checked={depositDetails.chain === cn.chain}
                            disabled={!cn.can_deposit}
                            onChange={() =>
                              setDepositDetails({
                                ...depositDetails,
                                chain: cn.chain,
                              })
                            }
                          />
                          <div
                            className={`check_custom_darkMode ${styles.check_custom}`}
                          ></div>
                          <span className="font_color_white">{cn.chain}</span>
                        </label>
                      </div>
                      <div
                        className={`font_color_white ${styles.amount_of_wage}`}
                      >
                      </div>
                      <div className={styles.line_last}></div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          <div className={styles.showNotificationOfNetwork}>
            <div className={styles.iconOfNotificationNetwork}>
              <img src={WaningIcon} alt="" width={20} height={20} />
            </div>
            {depositDetails?.chain === "LOCKED" ? (
              <div className={styles.titleNotificationNetwork}>
                توجه: در حال حاضر به دلیل بروز رسانی کیف پول ها امکان واریز
                مقدور نیست
              </div>
            ) : (
              <div className={styles.titleNotificationNetwork}>
                توجه :حداقل مبلغ واریز{" "}
                <span>
                  {depositDetails?.currentNetwork[0]?.deposit_least_amount}
                </span>{" "}
                میباشد. اگر مقدار ارز شما کمتر از این مقدار باشد مقدار قابل
                نمایش نمیباشد و تا زمانی که به حداقل میزان واریزی برسد در کیف
                پول شما قابل مشاهده نمیباشد.
              </div>
            )}
          </div>
          <section>
            <div className={styles.container_of_wallet}>
              {!isEmpty(coinNetwork) &&
              currentUserWallet.platform &&
              currentUserWallet.address ? (
                <>
                  <div className={styles.wallet_address}>
                    <div className={styles.QRCode_address}>
                      {currentUserWallet.address && (
                        <QRCodeMaker
                          address={
                            coinNetwork.withTag.DepositWithTagCoins.includes(
                              chainAsset
                            )
                              ? currentUserWallet.address.split(":")[0]
                              : currentUserWallet.address
                          }
                        />
                      )}
                    </div>
                    <div
                      className={`font_color_white ${styles.address_network_name}`}
                    >
                      آدرس در شبکه <span> {currentUserWallet.platform}</span>
                    </div>
                    <div className={styles.container_of_address_text_wallet}>
                      <div className={styles.input_show_wallet_address}>
                        <div
                          className={`changeBackgroundColorToHardBlack ${styles.place_of_value}`}
                        >
                          <div className="font_color_white">
                            {coinNetwork.withTag.DepositWithTagCoins.includes(
                              chainAsset
                            )
                              ? currentUserWallet.address.split(":")[0]
                              : currentUserWallet.address}
                          </div>
                        </div>
                      </div>
                      <div
                        className={styles.copy_button}
                        onClick={() =>
                          CopyThisText(
                            coinNetwork.withTag.DepositWithTagCoins.includes(
                              chainAsset
                            )
                              ? currentUserWallet.address.split(":")[0]
                              : currentUserWallet.address
                          )
                        }
                      >
                        <img
                          src={CopyIcon}
                          alt="COPY"
                          width={15}
                          height={15}
                          className="convertPicToGreen"
                        />
                      </div>
                      <div
                        className={`font_color_white ${styles.title_for_use_wallet}`}
                      >
                        جهت دریافت ارز از آدرس زیر استفاده کنید.
                      </div>
                    </div>
                  </div>
                  {!isEmpty(coinNetwork) &&
                    coinNetwork.withTag.DepositWithTagCoins.includes(
                      chainAsset
                    ) && (
                      <div className={styles.tagInput_container}>
                        <div
                          className={`font_color_white eng-number-font ${styles.memoQrPlace}`}
                        >
                          {currentUserWallet.address && (
                            <QRCodeMaker
                              address={currentUserWallet.address.split(":")[1]}
                            />
                          )}
                          {coinNetwork.withTag.TagName[chainAsset]}
                        </div>
                        <div
                          className={`changeBackgroundColorToHardBlack font_color_white ${styles.container_show}`}
                        >
                          <div className="eng-number-font">{currentUserWallet?.address?.split(":")[1]}</div>
                          <div
                            className={styles.copy_button}
                            onClick={() =>
                              CopyThisText(
                                currentUserWallet?.address?.split(":")[1]
                              )
                            }
                          >
                            <img
                              src={CopyIcon}
                              alt="COPY"
                              width={15}
                              height={15}
                              className="convertPicToGreen"
                            />
                          </div>
                        </div>
                        <div className={styles.titleNotificationNetwork}>
                          توجه: حتما در زمان ارسال از کیف پول مبدا به کیف پول
                          ارزفی
                          {coinNetwork.withTag.TagName[chainAsset]} را ثبت
                          نمایید
                        </div>
                      </div>
                    )}
                </>
              ) : (
                depositDetails?.chain !== "LOCKED" && (
                  <button
                    className={styles.button_new_wallet_address}
                    disabled={currentUserWallet.disableButton}
                    onClick={() => handleAddNewWallet()}
                  >
                    ایجاد آدرس جدید
                  </button>
                )
              )}
            </div>
          </section>
          <div className={styles.showNotificationForWalletAddress}>
            <div className={styles.iconOfNotificationNetwork}>
              <img src={WaningIcon} alt="" width={20} height={20} />
            </div>
            <div
              className={`changeTextColorToGreen ${styles.titleNotificationNetwork}`}
            >
              <p className="changeTextColorToGreen">توجه</p>

              <ul>
                <li>
                  این آدرس فقط در شبکه{" "}
                  {depositDetails?.currentNetwork[0]?.chain} معتبر میباشد و
                  واریز ارز در سایر شبکه هاامکان نمابش یا بازیابی ندارند.
                </li>
                <li>
                  حداقل مبلغ واریزی ‍{" "}
                  {depositDetails?.currentNetwork[0]?.deposit_least_amount}{" "}
                  میباشد. اگر مقدار شما کمتر از این مقدار باشد مقدار قابل نمایش
                  نمیباشد و تا زمانی که به حداقل میزان واریزی برسد در کیف پول
                  شما قابل مشاهده نمیباشد.
                </li>
                <li>
                  واریزی شما پس از تایید کامل در شبکه در کیف پول شما قابل مشاهده
                  خواهد بود.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
