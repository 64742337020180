import { useEffect, useRef, useState } from "react";
import { context } from "./context";
import axios from "axios";
import { withRouter, useHistory } from "react-router";
import {
  checkEmail,
  checkMobileNum,
  checkValidOfRepeat,
  checkMinimumPass,
} from "../components/Validator/Validator";
import {
  ERROR_CODE1,
  ERROR_CODE2,
  ERROR_CODE3,
  ERROR_CODE4,
  ERROR_CODE5,
} from "../components/Validator/ErrorCodes";
import {
  confirmCodeForRegister,
  userEditPassWithRecoveryHashCode,
  userLoginToApp,
  userRecoveryPass,
  userRegisterToApp,
} from "../services/userServices";
import { successMessage, errorMessage } from "../utils/messages";
import { useDispatch } from "react-redux";
import { addUserHashCode } from "../actions/user";
import { num2English } from "../utils/priceFunc";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const AuthUserContext = ({ children }) => {
  //Function add token to Axios
  const addToAxiosToken = (data) => {
    axios.defaults.headers.common[
      "x-access-token"
    ] = `${data.token.accessToken}`;
  };
  //End function add token to axios
  const dispatch = useDispatch();
  const history = useHistory();
  //All States
  const [buttonDisabled, setButtonDisabled] = useState(false);
  //For Login
  const [userEmail, setUserEmail] = useState("");
  const [userNumber, setUserNumber] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [captchaDetails, setCaptchaDetails] = useState({
    token: "",
    refresh: false,
  });
  const [validatorLogin, setValidatorLogin] = useState({
    number: "",
    email: "",
  });
  //
  //For Register
  const [newUserEmail, setNewUserEmail] = useState("");
  const [newUserNumber, setNewUserNumber] = useState("");
  const [newReferral, setNewReferral] = useState("");
  const [verifyCodeRegister, setVerifyCodeRegister] = useState("");
  const [registerAccSteps, setRegisterAccSteps] = useState({
    showVerifyCode: false,
    activeSetPassword: false,
    withPhone: false,
  });
  const [disableNewRef, setDisableNewRef] = useState(false);
  ///> > > Finally Registration "REGISTER SECTION"
  const [newUserPassword, setNewUserPassword] = useState("");
  const [repeatNewUserPassword, setRepeatNewUserPassword] = useState("");
  const [validatorRegister, setValidatorRegister] = useState({
    number: "",
    email: "",
    verifyCode: "",
    password: "",
    repeatPassword: "",
  });
  //

  //For Recovery Account
  const [numRecovery, setNumRecovery] = useState("");
  const [emailRecovery, setEmailRecovery] = useState("");
  const [verifyCodeRecovery, setVerifyCodeRecovery] = useState();
  const [hashCodeRecovery, setHashCodeRecovery] = useState("");
  const [recoveryAccSteps, setRecoveryAccSteps] = useState({
    showVerifyCode: false,
    showNewPassword: false,
    withPhone: false,
  });
  // > > > For Add New PassWord in "RECOVERY SECTION"
  const [changePassword, setChangePassword] = useState("");
  const [repeatChangePassword, setRepeatChangePassword] = useState("");
  const [validatorRecovery, setValidatorRecovery] = useState({
    number: "",
    email: "",
    verifyCode: "",
    password: "",
    repeatPassword: "",
  });
  //

  //For check URL's
  //Cleared, OPTIMIZED APPLICATION
  //
  useEffect(() => {
    const refer = localStorage.getItem("refer_code");
    if (refer) {
      setNewReferral(refer);
      setDisableNewRef(true);
    }
  }, []);

  const handleLoginNumber = async (event) => {
    event.preventDefault();

    let isValidNumber = await checkMobileNum(num2English(userNumber));
    if (!isValidNumber)
      return setValidatorLogin({ ...validatorLogin, number: ERROR_CODE1 });
    try {
      setButtonDisabled(true);
      const byPhone = true;
      const remember = false;
      const { data } = await userLoginToApp(
        num2English(userNumber),
        userPassword,
        byPhone,
        remember,
        captchaDetails.token
      );
      if (data) {
        successMessage(data.msg);
        localStorage.setItem("user", JSON.stringify(data.user));
        localStorage.setItem("token", data.token.accessToken);
        dispatch(addUserHashCode());
        setUserPassword("");
        setUserNumber("");
        addToAxiosToken(data);
        setButtonDisabled(false);
        history.replace("/");
      }
    } catch (ex) {
      setButtonDisabled(false);
      if (ex) {
        errorMessage("شماره موبایل یا رمز ورود اشتباه است");
      }
    } finally {
      setCaptchaDetails((prev) => ({
        ...prev,
        token: "",
        refresh: !prev.refresh,
      }));
    }
  };
  const handleLoginEmail = async (event) => {
    event.preventDefault();
    let isValidEmail = await checkEmail(userEmail);
    if (!isValidEmail)
      return setValidatorLogin({ ...validatorLogin, email: ERROR_CODE2 });
    if (!userPassword) return;
    try {
      setButtonDisabled(true);
      const byPhone = false;
      const remember = false;
      const { data } = await userLoginToApp(
        userEmail,
        userPassword,
        byPhone,
        remember
      );
      if (data) {
        successMessage(data.msg);
        localStorage.setItem("token", data.token.accessToken);
        localStorage.setItem("user", JSON.stringify(data.user));
        dispatch(addUserHashCode());
        setUserPassword("");
        setUserEmail("");
        addToAxiosToken(data);
        setButtonDisabled(false);
        history.replace("/");
      }
    } catch (ex) {
      setButtonDisabled(false);
      if (ex) {
        errorMessage("ایمیل یا رمز ورود اشتباه است");
      }
    }
  };
  //Registration Functions
  const handleRegisterNum = async (event) => {
    event.preventDefault();
    let isValidNumber = checkMobileNum(num2English(newUserNumber));
    if (!isValidNumber)
      return setValidatorRegister({
        ...validatorRegister,
        number: ERROR_CODE1,
      });
    try {
      const { data } = await confirmCodeForRegister(
        {
          phoneNumber: num2English(newUserNumber),
          captchaToken: captchaDetails.token,
        },
        true
      );
      if (data.verify) {
        setRegisterAccSteps({
          ...registerAccSteps,
          showVerifyCode: true,
          withPhone: true,
        });
      } else {
        errorMessage(data.message);
      }
    } catch (ex) {
      console.log(ex);
    } finally {
      setCaptchaDetails((prev) => ({
        ...prev,
        token: "",
        refresh: !prev.refresh,
      }));
    }
  };
  const handleRegisterEmail = async (event) => {
    event.preventDefault();
    let isValidEmail = checkEmail(newUserEmail);
    if (!isValidEmail)
      return setValidatorRegister({ ...validatorRegister, email: ERROR_CODE2 });
    try {
      const { data } = await confirmCodeForRegister({ email: newUserEmail });
      if (data.verify) {
        setRegisterAccSteps({
          ...registerAccSteps,
          showVerifyCode: true,
          withPhone: false,
        });
      } else {
        errorMessage(data.message);
      }
    } catch (ex) {
      console.log(ex);
    }
  };
  const handleRegisterCodeVerify = async (value) => {
    try {
      if (value) {
        setRegisterAccSteps({ ...registerAccSteps, activeSetPassword: true });
      } else {
        setValidatorRegister({ ...validatorRegister, verifyCode: ERROR_CODE5 });
      }
    } catch (ex) {
      console.log(ex);
    }
  };
  const handleNewUserCompleteRegistration = async (e) => {
    e.preventDefault();
    let isMinPass = checkMinimumPass(newUserPassword);
    if (!isMinPass)
      return setValidatorRegister({
        ...validatorRegister,
        password: ERROR_CODE3,
      });
    let checkRepeatPass = checkValidOfRepeat(
      newUserPassword,
      repeatNewUserPassword
    );
    if (!checkRepeatPass)
      return setValidatorRegister({
        ...validatorRegister,
        repeatPassword: ERROR_CODE4,
      });
    try {
      let body;
      if (!registerAccSteps.withPhone) {
        body = {
          email: newUserEmail,
          code: verifyCodeRegister,
          password: newUserPassword,
          confirm: repeatNewUserPassword,
          referCode: newReferral,
        };
      } else {
        body = {
          mobileNumber: newUserNumber,
          code: verifyCodeRegister,
          password: newUserPassword,
          confirm: repeatNewUserPassword,
          referCode: newReferral,
        };
      }
      const { data } = await userRegisterToApp(
        body,
        !registerAccSteps.withPhone
      );
      if (data.error) {
        errorMessage(data.message);
      } else {
        successMessage(data.message);
        setRegisterAccSteps({ ...registerAccSteps, showVerifyCode: false });
        setNewUserEmail("");
        setNewReferral("");
        setNewUserPassword("");
        setNewUserNumber("");
        history.replace("/authorization/login");
      }
    } catch (ex) {
      if (ex.message.includes("400")) {
        if (registerAccSteps.withPhone) {
          errorMessage("این شماره قبلا ثبت شده است");
        } else {
          errorMessage("این ایمیل قبلا ثبت شده است");
        }
      }
    }
  };

  //Recovery Handle Functions
  const handleRecoveryNum = async (event) => {
    event.preventDefault();
    let isValidNumber = checkMobileNum(numRecovery);
    if (!isValidNumber)
      return setValidatorRecovery({
        ...validatorRecovery,
        number: ERROR_CODE1,
      });
    try {
      setButtonDisabled(true);
      const { data } = await userRecoveryPass(
        { phoneNumber: numRecovery, captchaToken: captchaDetails.token },
        true
      );
      if (data.error) {
        errorMessage(data.message);
      }
      if (data.verify) {
        setHashCodeRecovery(data.hash);
        successMessage(data.message);
        setRecoveryAccSteps({
          ...recoveryAccSteps,
          showVerifyCode: true,
          withPhone: true,
        });
      }
    } catch (ex) {
      console.log(ex);
    } finally {
      setButtonDisabled(false);
      setCaptchaDetails((prev) => ({
        ...prev,
        token: "",
        refresh: !prev.refresh,
      }));
    }
  };
  const handleRecoveryEmail = async (event) => {
    event.preventDefault();
    let isValidEmail = await checkEmail(emailRecovery);
    if (!isValidEmail)
      return setValidatorRecovery({ ...validatorRecovery, email: ERROR_CODE2 });
    try {
      setButtonDisabled(true);
      const { data } = await userRecoveryPass({ email: emailRecovery });
      if (data.error) {
        errorMessage(data.message);
      }
      if (data.verify) {
        setHashCodeRecovery(data.hash);
        successMessage(data.message);
        setRecoveryAccSteps({
          ...recoveryAccSteps,
          showVerifyCode: true,
          withPhone: false,
        });
      }
    } catch (ex) {
      console.log(ex);
    } finally {
      setButtonDisabled(false);
    }
  };
  const handleVerifyCodeRecovery = async (event) => {
    event.preventDefault();
    if (!verifyCodeRecovery || verifyCodeRecovery.length !== 5) {
      return;
    }
    try {
      setRecoveryAccSteps({
        ...recoveryAccSteps,
        showVerifyCode: false,
        showNewPassword: true,
      });
    } catch (ex) {
      console.log(ex);
    }
  };
  const handleChangeUserPassword = async (event) => {
    event.preventDefault();
    let isMinPass = await checkMinimumPass(changePassword);
    if (!isMinPass)
      return setValidatorRecovery({
        ...validatorRecovery,
        password: ERROR_CODE3,
      });
    let checkRepeatPass = checkValidOfRepeat(
      changePassword,
      repeatChangePassword
    );
    if (!checkRepeatPass)
      return setValidatorRecovery({
        ...validatorRecovery,
        repeatPassword: ERROR_CODE4,
      });
    try {
      const { data } = await userEditPassWithRecoveryHashCode(
        {
          code: verifyCodeRecovery,
          hash: hashCodeRecovery,
          password: changePassword,
          confirm: repeatChangePassword,
        },
        recoveryAccSteps.withPhone
      );
      if (data.error) {
        errorMessage(data.message);
      } else {
        successMessage(data.message);
        setHashCodeRecovery("");
        setNumRecovery("");
        setChangePassword("");
        setRepeatChangePassword("");
        setRecoveryAccSteps({
          ...recoveryAccSteps,
          showVerifyCode: false,
          showNewPassword: false,
        });
        history.replace("/authorization/login");
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  return (
    <>
      {/* <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey="6LfBTA8qAAAAAF75WDoL6ZMYrkUh25VMEkDmZbYi"
      /> */}

      <GoogleReCaptchaProvider reCaptchaKey="6LeO5A8qAAAAAGAJPBAzoTyq6BFuKWzJStUwke7T" size="invisible"  >
        <context.Provider
          value={{
            //State of Login User
            userEmail,
            userNumber,
            userPassword,
            newUserEmail,
            newUserNumber,
            numRecovery,
            changePassword,
            newReferral,
            emailRecovery,
            recoveryAccSteps,
            verifyCodeRecovery,
            repeatChangePassword,
            registerAccSteps,
            newUserPassword,
            repeatNewUserPassword,
            verifyCodeRegister,
            buttonDisabled,
            disableNewRef,
            setDisableNewRef,
            setButtonDisabled,
            setVerifyCodeRegister,
            setNewUserPassword,
            setRepeatNewUserPassword,
            handleNewUserCompleteRegistration,
            setRegisterAccSteps,
            setRepeatChangePassword,
            setVerifyCodeRecovery,
            setRecoveryAccSteps,
            setEmailRecovery,
            setNewReferral,
            setUserPassword,
            setUserEmail,
            setUserNumber,
            setNewUserEmail,
            setNumRecovery,
            setNewUserNumber,
            setChangePassword,
            handleLoginNumber,
            handleLoginEmail,
            handleRegisterNum,
            handleRegisterEmail,
            handleRecoveryNum,
            handleRecoveryEmail,
            handleVerifyCodeRecovery,
            handleRegisterCodeVerify,
            handleChangeUserPassword,
            //This bottom props for handle errors ''
            validatorLogin,
            setValidatorLogin,
            validatorRegister,
            setValidatorRegister,
            validatorRecovery,
            setValidatorRecovery,
            captchaDetails,
            setCaptchaDetails,
          }}
        >
          {children}
        </context.Provider>
      </GoogleReCaptchaProvider>
    </>
  );
};
export default withRouter(AuthUserContext);
