export const getCyprtoExchangeState = (
  recivedData = {},
  exchangeActives = []
) => {
  let copyActiveSymbols = [...exchangeActives];
  const convertedData = {};

  copyActiveSymbols.forEach((item) => {
    if (recivedData[item]) {
      convertedData[item] = recivedData[item];
    }
  });

  return convertedData;
};

export const getCalculatingDeth = (data) => {
  let correctData = data[Object.keys(data)[0]];
  const refactored_data = {
    buyers: [],
    sellers: [],
    checksum: correctData.checksum,
    last: correctData.last,
    sell_avg: 0,
    buy_avg: 0,
  };
  if (data) {
    let sum_sell_price = 0;
    let sum_buy_price = 0;

    let sum_sell_price_value = 0;
    let sum_buy_price_value = 0;

    //# Ask Values
    correctData.asks.forEach((askArray) => {
      sum_sell_price += parseFloat(askArray[1]);
      sum_sell_price_value += parseFloat(askArray[0] * askArray[1]);
      refactored_data.sellers.push({
        price: askArray[0],
        value: askArray[1],
        sum: parseFloat(sum_sell_price),
        avg_amount: sum_sell_price_value,
      });
    });
    refactored_data["sell_avg"] = sum_sell_price / refactored_data.checksum;

    //# Buy Values
    correctData.bids.forEach((askArray) => {
      sum_buy_price += parseFloat(askArray[1]);
      sum_buy_price_value += parseFloat(askArray[0] * askArray[1]);

      refactored_data.buyers.push({
        price: askArray[0],
        value: askArray[1],
        sum: parseFloat(sum_buy_price),
        avg_amount: sum_buy_price_value,
      });
    });
    refactored_data["buy_avg"] = sum_buy_price / refactored_data.checksum;

    return { [[Object.keys(data)[0]]]: refactored_data };
  }
  return null;
};

export const getCalculatingDeals = (data) => {
  if (data) {
    const symbol = Object.keys(data)[0];
    const correctData = data[symbol];

    let mergeTwoArray = correctData.buy.concat(correctData.sell);
    mergeTwoArray.sort(function name(a, b) {
      return b.created_at - a.created_at;
    });

    return { [symbol]: mergeTwoArray };
  }
  return null;
};
