//* --> Styles
import { useSelector } from "react-redux";
import styles from "./PercentTrade.module.scss";
import { useMemo } from "react";
import { isEmpty } from "lodash";

const percentChanges = {};
export default function PercentTrade() {
  const depthReducer = useSelector((state) => state.depthReducer);
  const percents = useMemo(() => {
    const defaultState = { buy: 50, sell: 50 };
    if (!isEmpty(depthReducer)) {
      const symbol = Object.keys(depthReducer)[0];
      const buySum =
        depthReducer[symbol].buyers[depthReducer[symbol].buyers.length - 1].sum;
      const sellSum =
        depthReducer[symbol].sellers[depthReducer[symbol].sellers.length - 1]
          .sum;

      const totals = buySum + sellSum;
      defaultState["buy"] = ((buySum * 100) / totals).toFixed(2);
      defaultState["sell"] = ((sellSum * 100) / totals).toFixed(2);
    }
    return defaultState;
  }, [depthReducer]);
  
  return (
    <section
      className={`border-blue border-radius-3 --bg-trade_w_b ${styles.PercentTrade_container}`}
    >
      <div className={styles.percent_show}>
        <div
          className={`color_green_STATIC eng-number-font ${styles.percent_buy}`}
        >
          <div className={styles.percent_buy_show}>{percents.buy}%</div>
        </div>
        <div
          className={`color_red_STATIC eng-number-font ${styles.percent_sell}`}
        >
          <div className={styles.percent_sell_show}>{percents.sell}%</div>
        </div>
      </div>
      <div className={styles.line_show}>
        <div
          className={`bg_red_STATIC ${styles.negative}`}
          style={{ width: percents.sell + "%" }}
        ></div>
        <div
          className={`${styles.positive}`}
          style={{ width: percents.buy + "%" }}
        ></div>
      </div>
    </section>
  );
}
