import { Link } from "react-router-dom";
import styles from "./UserProfile.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { userProfile } from "../../../actions/user";
import { useHistory } from "react-router";

//Logo
import LogoApp from "../../../styles/pics/logo.png";
//Pic & Icon
import NoProfilePic from "../../../styles/pics/user-fill.svg";
import ArrowGray from "../../../styles/icon/ArrowGray.svg";
import { errorMessage } from "../../../utils/messages";
import { useState } from "react";
import { PROFILE_ROUTES } from "./profile_routes";
import appIcons from "../../../utils/appIcons";
import { Collapse } from "@mui/material";

export default function UserProfile() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [exitModal, setExitModal] = useState(false);
  const [subMenuSelected, setSubMenuSelected] = useState("");
  const userInfo = useSelector((state) => state.fullUserInfo.data_profile);
  //##
  const handleOpenConfirmQuitAccount = () => {
    setExitModal(true);
  };
  const openImber = () => {
    try {
      window.Raychat.openWidget();
      const rayChatEle = document.getElementById("raychat_widget");
      rayChatEle.style.display = "block";
    } catch (ex) {
      if (ex.message.includes("window.$imber.open is not a function")) {
        errorMessage("پشتیبانی از ۹ صبح تا ۱۲ شب فعال میباشد");
      }
    }
  };
  //##
  const ROUTES_FUNCS = {
    handleQuit: handleOpenConfirmQuitAccount,
    handleOpenImber: openImber,
  };

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      if (!userInfo) dispatch(userProfile());
    }
    return () => (mounted = false);
  }, []);

  return (
    <>
      {exitModal && (
        <div className={styles.user_exit_modal}>
          <div
            className={`changeBackgroundColorToHardBlack ${styles.confirm_container}`}
          >
            <div className="font_color_white">
              از اکانت کاربری خود خارج میشوید؟
            </div>
            <div>
              <button onClick={() => history.push("/logout")}>!خارج شو</button>
              <button onClick={() => setExitModal(false)}>خیر</button>
            </div>
          </div>
        </div>
      )}
      {/*
     End Of Modal For Exit User 
     */}
      <div className={`${styles.container} font_color_white`}>
        <div className={styles.logo_container}>
          <img src={LogoApp} alt="ArzFi" width={89} height={55} />
        </div>
        <header className={styles.headerStatus_container}>
          <section
            className={`changeBackgroundColorToHardBlack change_color_to_E1E2E4 ${styles.headerStatus_bg_container}`}
          >
            <div className={styles.user_name}>{userInfo?.user?.fullname}</div>
            <div className={styles.status_acc}>
              <span>وضعیت:‌ </span>
              <span>سطح ۱</span>
            </div>
            {userInfo?.user?.verified !== true && (
              <div className={styles.finish_verify_button}>
                <Link to="/my-profile/authentication">تکمیل احراز هویت</Link>
              </div>
            )}
          </section>
          <section className={styles.user_pic}>
            <img src={NoProfilePic} alt="AVATAR" width={75} height={75} />
          </section>
        </header>
        <div className={styles.router_container}>
          {PROFILE_ROUTES.map((ctx, idx) => {
            let subLinks = ctx.hasOwnProperty("children");
            let clickAction = ctx.hasOwnProperty("onClick");
            if (subLinks)
              return (
                <div
                  key={idx}
                  className={styles.list_container_type_one}
                  data-active={subMenuSelected === ctx.id}
                  onClick={() =>
                    setSubMenuSelected((prev) =>
                      prev !== ctx.id ? ctx.id : ""
                    )
                  }
                >
                  <div className={styles.open_list_container}>
                    <p>
                      <span>{appIcons(ctx.icon)}</span>
                      {ctx.title}
                    </p>
                    <span
                      className={styles.svg}
                      data-active={subMenuSelected === ctx.id}
                    >
                      {appIcons("ArrowDownIcon")}
                    </span>
                  </div>
                  <Collapse in={subMenuSelected === ctx.id}>
                    {ctx.children.map((sub, subIdx) => {
                      return (
                        <Link to={sub.route} key={subIdx}>
                          <div className={styles.sub_container}>
                            <span>{appIcons("ArrowBackIcon")}</span>
                            <p>{sub.title}</p>
                          </div>
                        </Link>
                      );
                    })}
                  </Collapse>
                </div>
              );
            if (clickAction)
              return (
                <div
                  className={styles.list_container_type_two}
                  key={idx}
                  onClick={ROUTES_FUNCS[ctx.onClick]}
                >
                  <p>
                    <span>{appIcons(ctx.icon)}</span>
                    {ctx.title}
                  </p>
                </div>
              );
            return (
              <Link
                to={ctx.route}
                className={styles.list_container_type_two}
                key={idx}
              >
                <div>
                  <p>
                    <span>{appIcons(ctx.icon)}</span>
                    {ctx.title}
                  </p>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </>
  );
}
