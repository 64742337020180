export function formatPrice(value) {
    let isNegative = value < 0;
    value = Math.abs(value);

    let formattedValue;

    if (value >= 100) {
        formattedValue = value.toFixed(2);
    } else if (value >= 10 && value < 100) {
        formattedValue = value.toFixed(3);
    } else if (value >= 1 && value < 10) {
        formattedValue = value.toFixed(3);
    } else {
        formattedValue = value.toPrecision(9).replace(/0+$/, ''); 
    }
    if (isNegative) {
        formattedValue = '-' + formattedValue;
    }
    return {
        number: parseFloat(formattedValue),
        string: formattedValue
    };
}