import React, { useEffect, useState } from "react";
import { io } from "socket.io-client";
import pako from "pako";
import {
  getCalculatingDeals,
  getCalculatingDeth,
  getCyprtoExchangeState,
} from "../utils/socket.utils";
import { FetchPairs } from "../services/marketServices";
import { useDispatch } from "react-redux";
import {
  updateSocketStateAction,
  updateSocketDepthAction,
  updateSocketDealsAction,
} from "../actions/SocketIO_/socket.actions";
import configService from "../services/config.json";

const handleCompressedMessage = (base64Message) => {
  try {
    const decodedData = atob(base64Message);
    const binaryData = new Uint8Array(
      decodedData.split("").map((char) => char.charCodeAt(0))
    );
    const decompressedData = pako.inflate(binaryData, { to: "string" });
    const parsedData = JSON.parse(decompressedData);
    return parsedData;
  } catch (error) {
    console.error("Error handling compressed message:", error);
  }
};

const getPairsList = async () => {
  try {
    if (window.pairList) {
      console.log("window");
      return window.pairList;
    }
    console.log("api");
    const { data } = await FetchPairs();
    window.pairList = data.Data.ArzFi.pairs;
    return data.Data.ArzFi.pairs;
  } catch (error) {
    return null;
  }
};

//onEmitted -> param1->name,param2->response ex: onEmitted('state.subscribe','response')
function useSocketConnection(
  susbList = "",
  onEmitted = null,
  saveInActions = false
) {
  const dispatch = useDispatch();
  //String split by ',' like state,depth.BTCIRT,deals.BTCIRT
  const [client, setClient] = useState(null);
  useEffect(() => {
    let intervalPing;
    let client;
    (async () => {
      const pairList = [];
      const subscribeList = susbList.split(",");
      const pairData = null;

      if (pairData) {
        for (const key in pairData) {
          const pairsItems = pairData[key];
          pairsItems.forEach((value) => {
            pairList.push(key + value);
          });
        }
      }

      //requestList -> obj {key & value}
      const requestList = [];
      for (let i = 0; i < subscribeList.length; i++) {
        const reqItem = subscribeList[i];
        if (reqItem === "state") {
          requestList.push({ key: "state.subscribe" });
        }
        const splittedByDot = reqItem.split(".");
        if (splittedByDot.length === 2) {
          if (splittedByDot[0] === "depth") {
            requestList.push({
              key: "depth.subscribe",
              value: splittedByDot[1],
            });
          } else if (splittedByDot[0] === "deals") {
            requestList.push({
              key: "deals.subscribe",
              value: splittedByDot[1],
            });
          }
        }
      }
      const wsUrl = configService.socket2_url;
      client = new io(wsUrl, {
        reconnection: true,
        reconnectionAttempts: Infinity,
        reconnectionDelay: 1000,
        transports: ["websocket"],
      });

      client.on("connect", () => {
        setClient(client);
        console.log("client connected via request: ", requestList);
        intervalPing = setInterval(() => {
          client.emit("heartbeat");
        }, 10_000);

        requestList.forEach((item) => {
          client.emit(item.key, item.value);
        });
        client.onAny((key, value) => {
          if (key) {
            if (key === "state.update") {
              let unComp;
              if (pairList.length) {
                unComp = getCyprtoExchangeState(
                  handleCompressedMessage(value),
                  pairList
                );
              } else {
                unComp = handleCompressedMessage(value);
              }
              if (onEmitted) onEmitted(key, unComp);
              if (saveInActions) {
                dispatch(updateSocketStateAction(unComp));
              }
            } else {
              if (saveInActions) {
                if (key === "deals.update") {
                  dispatch(
                    updateSocketDealsAction(
                      getCalculatingDeals({ [value["symbol"]]: value.data })
                    )
                  );
                } else if (key === "depth.update") {
                  dispatch(
                    updateSocketDepthAction(
                      getCalculatingDeth({ [value["symbol"]]: value.data })
                    )
                  );
                }
              }
              if (onEmitted) onEmitted(key, value);
            }
          }
        });
      });
      client.on("disconnect", () => {
        clearInterval(intervalPing);
        console.log("client disconnected");
        setClient(null);
      });
    })();
    return () => {
      if (client) client.disconnect();
      if (intervalPing) clearInterval(intervalPing);
    };
  }, [susbList, saveInActions]);

  return client;
}

export default useSocketConnection;
