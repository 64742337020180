import { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getCoinDetail } from "../../../actions/getCoinDetail";
import { isEmpty } from "lodash";
import { usersMakeOrders } from "../../../services/adminServices";
import {
  num2English,
  showPrice,
  showUserBalance,
  toFixedE,
} from "../../../utils/priceFunc";
import { userCoinBalance } from "../../../actions/user";
import Num2persian from "num2persian";
//Components
import LayoutCoin from "./LayoutCoin";
import ErrorMessageHook from "../../../helpers/errors_notifications/ErrorMessage";
import { errorMessage, successMessage } from "../../../utils/messages";
import {
  coinUsersBalance,
  FetchMakeMarketOrder,
} from "../../../services/userServices";
import {
  hiddenLoadingPage,
  showLoadingPage,
} from "../../../actions/loadingPage";
//Styles and Icons
import styles from "./BuySell.module.scss";
import VerticalIcon from "../../../styles/icon/menu/Vertical_switch.svg";
import copyIcon from "../../../styles/icon/CopyIcon2.svg";
//* --> Modal For Confirm The Procees
import ConfirmModal from "../../Modals/ConfirmModal";
import MuiInput from "./MuiInput";
import { Stack } from "@mui/system";
import {
  NumberHasDecimal,
  showBetterPrice,
} from "../../../utils/showBetterPrice";
import { toast } from "react-toastify";
import EXECEPTED_ERRORS from "./EXCEPTED_ERROS";
import { DangerMuiBtn, SuccessMuiBtn } from "../../MuiButtons";
import { Typography } from "@mui/material";

const TYPE_MARKET = {
  IRT: "تومان",
  USDT: "تتر",
};

let TIMER_FORCE_UPDATE_PRICEES;
function showBetterPriceUsed(num) {
  let thisNum = num;
  if (isNaN(thisNum)) thisNum = 0;
  const prettier = showBetterPrice(thisNum);
  if (thisNum.toString().includes("e")) {
    const newNum = toFixedE(thisNum);
    return newNum;
  }
  return prettier;
}
export default function BuyCoin() {
  //# Hooks
  const history = useHistory();
  const dispatch = useDispatch();
  const { name } = useParams();
  //# State
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [switchButton, setSwitchButton] = useState(false);
  const [coinValue, setCoinValue] = useState("");
  const [priceValue, setPriceValue] = useState("");
  const [messageBox, setMessageBox] = useState("");
  const [coinUserBalance, setCoinUserBalance] = useState({});
  const [feePrice, setFeePrice] = useState(0);
  const [mustBeUpdate, setMustBeUpdate] = useState({
    lastEdit: "",
    timer: 0,
  });
  //Store State's
  const COIN_DATA = useSelector((state) => state.coinInfo.data);
  const depthReducer = useSelector((state) => state.depthReducer);
  const symbolSocketPrices = useSelector((state) => state.stateReducer);
  const userId = useSelector((state) => state.userId);
  const userAreaDetails = useSelector(
    (state) => state.fullUserInfo.coinBalance
  );
  const marketListReducer = useSelector((state) => state.tradesList);
  //End State's
  const [openConfrimModal, setOpenConfirmModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [tradeError, setTradeError] = useState({});
  const [onPrice, setOnPrice] = useState(0);
  //# Check Type of Trade This Coin ## IRT Or USDT
  const AREA_TYPE = useMemo(() => {
    const searchArray = history.location.search.split("?");
    if (searchArray[1]) {
      const TypeIsExist = searchArray[1].split("type=");
      switch (TypeIsExist[1].toUpperCase()) {
        case "IRT":
          return "IRT";
        case "USDT":
          return "USDT";
        default:
          return "IRT";
      }
    }
    return "IRT";
  }, [history.location]);

  // # # # # # #
  function toggleError({ type, id, code }) {
    if (type === "add") {
      setTradeError((prev) => {
        return {
          ...prev,
          [id]: EXECEPTED_ERRORS[code],
        };
      });
    } else {
      setTradeError((prev) => {
        return {
          ...prev,
          [id]: { error: false },
        };
      });
    }
  }
  // # # # # # #

  const currentCoinSocket = useMemo(() => {
    if (!isEmpty(COIN_DATA)) {
      return symbolSocketPrices[COIN_DATA.symbol + AREA_TYPE] || {};
    } else {
      return {};
    }
  }, [symbolSocketPrices, AREA_TYPE, COIN_DATA]);

  const currentCoinAPIList = useMemo(() => {
    if (marketListReducer.hasOwnProperty("list") && !isEmpty(COIN_DATA)) {
      return (
        marketListReducer.list.filter(
          (c) => c.market === COIN_DATA.symbol + AREA_TYPE
        )[0] || {}
      );
    } else {
      return {};
    }
  }, [COIN_DATA, AREA_TYPE, marketListReducer]);

  ///// CALCULATOR
  const handleChangeAreaPrice = (e) => {
    if (AREA_TYPE === "IRT") {
      let calc;
      calc = num2English(e.target.value)?.replace(/\D/g, "");
      setPriceValue(calc);
      if (COIN_DATA) {
        const c1 = calc / COIN_DATA.arzfi_price;
        if (String(c1).includes("e")) return setCoinValue(0);
        const p = showBetterPrice(calc / COIN_DATA.arzfi_price);
        return setCoinValue(p);
      } else {
        return setCoinValue(0);
      }
    } else if (AREA_TYPE === "USDT") {
      handleChangeUSDTBalance(e.target.value);
    }
    return;
  };
  const handleChangeCoinPrice = (e) => {
    if (AREA_TYPE === "IRT") {
      const calc = num2English(e.target.value)?.match(/\d|\./g)?.join("") || "";
      setCoinValue(calc);
      const rialValue = calc * +COIN_DATA?.arzfi_price;
      setPriceValue(rialValue.toString()?.split(".")[0]);
      return;
    } else if (AREA_TYPE === "USDT") {
      return handleChangeCoinAmount(e.target.value);
    }
    return;
  };

  useEffect(() => {
    let mounted = true;
    const getThisCoinInfo = async () => {
      try {
        await dispatch(getCoinDetail(name));

        dispatch(hiddenLoadingPage());
      } catch (ex) {
        dispatch(showLoadingPage());
      }
    };
    //
    (async () => {
      dispatch(showLoadingPage());
      if (mounted) {
        await getThisCoinInfo();
      }
    })();
    //
    const reloadInfo = setInterval(() => {
      getThisCoinInfo();
    }, 15000);
    return () => {
      clearInterval(reloadInfo);
      mounted = false;
    };
  }, [name]);

  useEffect(() => {
    let mounted = true;
    if (mounted && AREA_TYPE && !isEmpty(userId) && userId.id !== "default") {
      dispatch(userCoinBalance(AREA_TYPE.toLocaleLowerCase()));
      getUserThisCoinBalance();
    }
    return () => {
      mounted = false;
    };
  }, [AREA_TYPE, userId]);

  useEffect(() => {
    return () => {
      setCoinValue("");
      setPriceValue("");
      setTradeError({});
      setMustBeUpdate({ lastEdit: "", timer: 0 });
    };
  }, [history.location]);

  //## Handle Update Price After User Not Activiy To Change
  useEffect(() => {
    let mounted = true;
    clearTimeout(TIMER_FORCE_UPDATE_PRICEES);
    if (
      mounted &&
      mustBeUpdate.timer !== 0 &&
      priceValue &&
      AREA_TYPE === "USDT"
    ) {
      TIMER_FORCE_UPDATE_PRICEES = setTimeout(() => {
        if (mustBeUpdate.lastEdit === "usdt") {
          handleChangeUSDTBalance(priceValue);
        } else {
          handleChangeCoinAmount(coinValue);
        }
      }, mustBeUpdate.timer);
    }
    return () => {
      clearTimeout(TIMER_FORCE_UPDATE_PRICEES);
      mounted = false;
    };
  }, [mustBeUpdate, AREA_TYPE]);

  const replaceToAmount = () => {
    if (!isEmpty(userId) && userId.id !== "default") {
      if (AREA_TYPE === "IRT") {
        setCoinValue(USER_AREA_BALANCE / COIN_DATA?.arzfi_price);
        setPriceValue(USER_AREA_BALANCE);
      } else {
        handleChangeUSDTBalance(USER_AREA_BALANCE);
      }
    }
  };
  const handleChangeUSDTBalance = (amount) => {
    setMustBeUpdate({ lastEdit: "usdt", timer: 5000 });
    let AMOUNT = amount;
    setPriceValue(AMOUNT);
    let PriceInDepth = 0;
    let CanTradeDepth = [];
    if (depthReducer[name + "USDT"]) {
      CanTradeDepth = depthReducer[name + "USDT"].sellers.filter((c) => {
        const EARNED = AMOUNT / c.price;
        if (c.value > EARNED) {
          return c;
        }
      });
    }
    if (CanTradeDepth[0]) {
      PriceInDepth = CanTradeDepth[0].price;
      toggleError({ type: "remove", id: 200 });
      setOnPrice(CanTradeDepth[0].price);
    } else {
      PriceInDepth = 0;
      toggleError({ type: "add", id: 200, code: 3 });
      setOnPrice(0);
    }
    let ExchangeFee =
      (currentCoinAPIList.fee_rate * Number(AMOUNT)) / PriceInDepth;
    let another = Number(AMOUNT / PriceInDepth) - ExchangeFee;
    if (another.toString().includes("e") || another < 0) {
      another = 0;
    } else {
      if (!(another % 2)) {
        another = Math.ceil(another);
      }
    }
    another = showBetterPrice(another);
    if (isNaN(another)) another = "";
    setFeePrice(currentCoinAPIList.fee_rate * Number(another) * PriceInDepth);
    setCoinValue(another);
  };

  const handleChangeCoinAmount = (e) => {
    setMustBeUpdate({ lastEdit: "coin", timer: 5000 });
    let AMOUNT = e;
    if (AMOUNT < 0) AMOUNT = 0;
    setCoinValue(AMOUNT);
    let PriceInDepth = 0;
    let CanTradeDepth = [];
    if (depthReducer[name + "USDT"]) {
      CanTradeDepth = depthReducer[name + "USDT"].sellers.filter((c) => {
        if (c.value > AMOUNT) {
          return c;
        }
      });
    }
    if (CanTradeDepth[0]) {
      
      PriceInDepth = CanTradeDepth[0].price;
      toggleError({ type: "remove", id: 200 });
      setOnPrice(CanTradeDepth[0].price);
    } else {
      PriceInDepth = 0;
      toggleError({ type: "add", id: 200, code: 3 });
      setOnPrice(0);
    }
    let ExchangeFee = currentCoinAPIList.fee_rate * AMOUNT * PriceInDepth;
    setFeePrice(ExchangeFee);
    let another = Number(AMOUNT * PriceInDepth) + ExchangeFee;
    if (another.toString().includes("e")) {
      another = 0;
    } else {
      let hasDec = NumberHasDecimal(another);
      if (hasDec) {
        another = another.toFixed(2);
        if (!(another % 2)) {
          another = Math.ceil(another);
        }
      }
    }
    setPriceValue(another);
  };

  const getUserThisCoinBalance = async () => {
    const { data } = await coinUsersBalance(name);
    setCoinUserBalance(data);
  };

  const handleMakeTrade = (e) => {
    if (AREA_TYPE === "IRT") {
      return handleBuyCoin(e);
    } else if (AREA_TYPE === "USDT") {
      e.preventDefault();
      return handleCommitBuyMarket();
    }
    return toast.error(`مشکلی در انجام عملیات رخ داده! UNSYNC`);
  };
  //Buy With IRT, API
  const handleBuyCoin = async (event) => {
    if (event.type === "submit") {
      event.preventDefault();
    }
    if (!+priceValue) return setMessageBox("تعداد یا مبلغ خرید وارد نشده است");
    if (+coinValue < +COIN_DATA.minAmount)
      return setMessageBox(
        `حداقل مقدار خرید ${COIN_DATA.faName} ${COIN_DATA.minAmount}`
      );
    if (+coinValue > +COIN_DATA.maxAmount)
      return setMessageBox(
        `حداکثر مقدار خرید ${COIN_DATA.faName} ${COIN_DATA.maxAmount}`
      );
    if (
      +userAreaDetails.asset.balance - +userAreaDetails.asset.locked <
      +priceValue
    )
      return setMessageBox("موجودی حساب شما کافی نمیباشد");
    let body;
    if (event === true) {
      body = {
        asset: name,
        confirmed: true,
        orderAmount: +priceValue,
        orderType: "buy",
      };
    } else {
      body = {
        asset: name,
        orderAmount: +priceValue,
        orderType: "buy",
      };
    }
    try {
      setDisabledBtn(true);
      const { data } = await usersMakeOrders(body);
      if (data.needConfirmation) {
        setOpenConfirmModal(true);
        setModalMessage(data.error);
        return;
      }
      if (data.error) {
        errorMessage(data.error);
      } else {
        successMessage(data.message);
        setPriceValue(0);
        setCoinValue(0);
        dispatch(userCoinBalance("irt"));
        getUserThisCoinBalance();
      }
    } catch (ex) {
      errorMessage("دوباره تلاش کنید");
    } finally {
      setDisabledBtn(false);
    }
  };

  const handleCommitBuyMarket = async () => {
    if (tradeError[200]) {
      if (tradeError[200].error) {
        return toast.error(`این مقدار برای خرید در بازار موجود نمیباشد`);
      }
    }
    if (!priceValue) return toast.error(`مبلغ خرید درست نمیباشد.`);
    if (Number(priceValue) > USER_AREA_BALANCE) {
      return toast.error(`موجودی تتر شما کافی نمیباشد.`);
    }
    try {
      setDisabledBtn(true);
      const { data } = await FetchMakeMarketOrder(
        JSON.stringify({
          market: currentCoinAPIList.market,
          orderType: "buy",
          orderAmount: priceValue,
        })
      );
      if (data.error) {
        errorMessage(data.error);
      }
      if (data.message) {
        setMustBeUpdate({ timer: 0, lastEdit: "" });
        setPriceValue(0);
        setCoinValue(0);
        successMessage(data.message);
        let timerDelay = setTimeout(() => {
          dispatch(userCoinBalance("usdt"));
          getUserThisCoinBalance();
          clearTimeout(timerDelay);
        }, 1500);
      }
    } catch (ex) {
      toast.error("مشکلی از سمت سرور پیش آمده است.");
    } finally {
      setDisabledBtn(false);
    }
  };

  const USER_AREA_BALANCE = useMemo(() => {
    if (!isEmpty(userId) || userId.id !== "default") {
      if (userAreaDetails && userAreaDetails.hasOwnProperty("asset")) {
        const CanUseAreaBalance =
          Number(userAreaDetails.asset.balance) -
          Number(userAreaDetails.asset.locked);
        return showUserBalance(CanUseAreaBalance);
      }
    } else {
      return 0;
    }
  }, [AREA_TYPE, userId, userAreaDetails]);

  const handleSwitchButton = () => {
    if (!switchButton) {
      setMustBeUpdate((prev) => ({ ...prev, lastEdit: "usdt" }));
    } else {
      setMustBeUpdate((prev) => ({ ...prev, lastEdit: "coin" }));
    }
    setSwitchButton((prev) => !prev);
  };

  //# Hidden For Securiry
  useEffect(() => {
    if (AREA_TYPE === "USDT") {
      if (isEmpty(currentCoinAPIList) || isEmpty(currentCoinSocket)) {
        dispatch(showLoadingPage());
      } else {
        dispatch(hiddenLoadingPage());
      }
    }
    return () => {
      dispatch(hiddenLoadingPage());
    };
  }, [currentCoinAPIList, currentCoinSocket, AREA_TYPE]);

  //# Handle Switch To sell
  const handleSwitchSell = (e) => {
    e.preventDefault();
    return history.replace(`/sell/${COIN_DATA["symbol"]}?type=${AREA_TYPE}`);
  };
  return (
    <>
      <LayoutCoin>
        {messageBox && (
          <ErrorMessageHook
            setMessageBox={setMessageBox}
            messageBox={messageBox}
          />
        )}

        <div className={styles.buyCoin_container}>
          <form onSubmit={handleMakeTrade}>
            {switchButton ? (
              <>
                <Stack width="93%" margin="0 auto">
                  <MuiInput
                    endAd={`مبلغ کل (${TYPE_MARKET[AREA_TYPE]})`}
                    fullWidth
                    placeholder={COIN_DATA?.minAmount}
                    onChange={handleChangeAreaPrice}
                    value={isNaN(priceValue) ? "" : priceValue}
                    type="number"
                    inputMode="decimal"
                  />
                </Stack>
                {AREA_TYPE === "IRT" && priceValue && (
                  <div
                    className={`font_color_white ${styles.show_persian_price}`}
                  >
                    {Num2persian(priceValue)} تومان
                  </div>
                )}
                <div className={styles.user_wallet_amount}>
                  <div className={`font_color_white ${styles.title_count}`}>
                    موجودی کیف پول شما:
                  </div>
                  <div
                    className={`font_color_white ${styles.user_coins}`}
                    onClick={replaceToAmount}
                  >
                    <div className={styles.symbol_coin}>
                      <img
                        src={copyIcon}
                        alt="bbb"
                        width={14}
                        height={14}
                        className="convertPicToGreen"
                      />{" "}
                      {TYPE_MARKET[AREA_TYPE]}
                    </div>
                    <div className={`eng-number-font ${styles.coin_amount}`}>
                      {USER_AREA_BALANCE || 0 || 0}
                    </div>
                  </div>
                </div>
                {AREA_TYPE !== "IRT" && (
                  <Stack className={styles.on_price_container}>
                    <Stack>میانگین قیمت خرید: </Stack>
                    <Stack className="eng-number-font">
                      {showBetterPriceUsed(onPrice || currentCoinSocket?.last)}{" "}
                      تتر
                    </Stack>
                  </Stack>
                )}
                <div className={styles.switch_container}>
                  <div
                    className={styles.button_change_to_sell}
                    onClick={handleSwitchButton}
                  >
                    <img src={VerticalIcon} alt="" width={25} height={25} />
                  </div>
                </div>
                <Stack width="93%" margin="0 auto" marginTop="10px">
                  <MuiInput
                    endAd={
                      <Typography fontSize="15px">
                        مقدار {COIN_DATA?.symbol} ≈
                      </Typography>
                    }
                    fullWidth
                    disabled
                    value={isNaN(priceValue) ? "" : showPrice(coinValue)}
                  />
                </Stack>
                <div className={styles.user_coin_count}>
                  <div className={`font_color_white ${styles.title_count}`}>
                    موجودی کیف پول شما:
                  </div>
                  <div className={`font_color_white ${styles.user_coins}`}>
                    <div className={styles.symbol_coin}>
                      {COIN_DATA?.symbol}
                    </div>
                    <div className={`eng-number-font ${styles.coin_amount}`}>
                      {(coinUserBalance?.asset &&
                        +coinUserBalance.asset.balance -
                          +coinUserBalance.asset.locked) ||
                        0}
                    </div>
                  </div>
                </div>
                {AREA_TYPE !== "IRT" && (
                  <Stack className={styles.on_price_container}>
                    <Stack>کارمزد: </Stack>
                    <Stack className="eng-number-font">
                      {isNaN(feePrice) ? 0 : feePrice.toFixed(2)} تتر
                    </Stack>
                  </Stack>
                )}
              </>
            ) : (
              <>
                <Stack width="93%" margin="0 auto">
                  <MuiInput
                    endAd={
                      <Typography fontSize="15px">
                        مقدار {COIN_DATA?.symbol} ≈
                      </Typography>
                    }
                    fullWidth
                    placeholder={COIN_DATA?.minAmount}
                    type="number"
                    onChange={handleChangeCoinPrice}
                    value={coinValue}
                    inputMode="decimal"
                  />
                </Stack>
                <div className={styles.user_coin_count}>
                  <div className={`font_color_white ${styles.title_count}`}>
                    موجودی کیف پول شما:
                  </div>

                  <div className={`font_color_white ${styles.user_coins}`}>
                    <div className={styles.symbol_coin}>
                      {COIN_DATA?.symbol}
                    </div>
                    <div className={`eng-number-font ${styles.coin_amount}`}>
                      {(coinUserBalance?.asset &&
                        +coinUserBalance.asset.balance -
                          +coinUserBalance.asset.locked) ||
                        0}
                    </div>
                  </div>
                </div>
                {AREA_TYPE !== "IRT" && (
                  <Stack className={styles.on_price_container}>
                    <Stack>کارمزد: </Stack>
                    <Stack className="eng-number-font">
                      {isNaN(feePrice) ? 0 : feePrice.toFixed(2)} تتر
                    </Stack>
                  </Stack>
                )}
                <div className={styles.switch_container}>
                  <div
                    className={styles.button_change_to_sell}
                    onClick={handleSwitchButton}
                  >
                    <img src={VerticalIcon} alt="" width={25} height={25} />
                  </div>
                </div>
                <Stack width="93%" margin="0 auto" marginTop="10px">
                  <MuiInput
                    endAd={`مبلغ کل (${TYPE_MARKET[AREA_TYPE]})`}
                    fullWidth
                    placeholder={COIN_DATA?.minAmount}
                    disabled
                    value={priceValue === "NaN" ? "" : showPrice(priceValue)}
                  />
                </Stack>
                {AREA_TYPE === "IRT" && priceValue && (
                  <div
                    className={`font_color_white  ${styles.show_persian_price}`}
                  >
                    {Num2persian(priceValue)} تومان
                  </div>
                )}

                <div className={styles.user_wallet_amount}>
                  <div className={`font_color_white ${styles.title_count}`}>
                    موجودی کیف پول شما:
                  </div>
                  <div
                    className={`font_color_white ${styles.user_coins}`}
                    onClick={replaceToAmount}
                  >
                    <div className={styles.symbol_coin}>
                      <img
                        src={copyIcon}
                        alt="crypto currency"
                        width={12}
                        height={12}
                        className="convertPicToGreen"
                      />
                      {TYPE_MARKET[AREA_TYPE]}
                    </div>
                    <div className={`eng-number-font ${styles.coin_amount}`}>
                      {USER_AREA_BALANCE || 0}
                    </div>
                  </div>
                </div>
                {AREA_TYPE !== "IRT" && (
                  <Stack className={styles.on_price_container}>
                    <Stack>میانگین قیمت خرید: </Stack>
                    <Stack className="eng-number-font">
                      {showBetterPriceUsed(onPrice || currentCoinSocket.last)}{" "}
                      تتر
                    </Stack>
                  </Stack>
                )}
              </>
            )}
            {tradeError[200] && tradeError[200].error && (
              <Stack className={styles.error_container}>
                {tradeError[200].message}
              </Stack>
            )}
            <div className={styles.button_place}>
              <Stack flex={1}>
                <DangerMuiBtn onClick={handleSwitchSell} text="فروش" />
              </Stack>
              <Stack flex={2}>
                <SuccessMuiBtn
                  type="submit"
                  disabled={isEmpty(userId) ? true : disabledBtn}
                  text={`خرید ${COIN_DATA?.faName ?? "--"}`}
                />
              </Stack>
            </div>
          </form>
          {isEmpty(userId) ? (
            <div className={` font_color_white ${styles.message_for_login}`}>
              ابتدا وارد{" "}
              <span
                onClick={() => history.push("/authorization/login")}
                className="font_color_change_to_green"
              >
                حساب کاربری
              </span>{" "}
              خود شوید
            </div>
          ) : (
            ""
          )}
        </div>
        <ConfirmModal
          open={openConfrimModal}
          setOpen={setOpenConfirmModal}
          message={modalMessage}
          process={handleBuyCoin}
        />
      </LayoutCoin>
    </>
  );
}
